<template>
  <div>
    <div class="smooth" :class="{ hidden: !flOpenedClubMenu }"></div>

    <div ref="settingsModalClub" v-if="clubOptions" id="club-settings" :class="{ opened: flOpenedClubMenu }">
      <div class="grip">
        <hr />
      </div>
      <div class="wrapper">
        <div>
          <div class="pin-club-btn" :class="{ pinned: +whoami.options.pinClub === +focusClubId }" @click="pinClub()"></div>

          <div class="club-mini-wrap" v-if="focusclub?.logo_id">
            <img class="club-mini-ico" :src="ATT_URL + 'pictures/' + focusclub.logo_id + '?s=75x75'" />

            <div class="club-mini-content">
              <div class="title">
                {{ focusclub.title }}
              </div>

              <div v-if="focusclub.directions && focusclub.directions.length && refDirections.length" class="dirbox">
                <small class="smalldir" v-for="td of focusclub.directions" v-bind:key="td">
                  {{ directionById(td).title.toLowerCase() }}
                </small>
              </div>
            </div>
          </div>

          <div class="options-buttons">
            <div class="button-theme icof icoF509" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="goSettings" href="/clubsettings">Настройки клуба</a></div>
            </div>

            <div class="button-theme icof icoF53F" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="goColorSettings" href="/clubcolorsettings">Вид</a></div>
            </div>

            <div class="button-theme icof icoF0С0" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="goClubteams" href="/clubteams">Команды клуба</a></div>
            </div>

            <div class="button-theme icof icoF508" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="goBosses" href="#">Руководство клуба</a></div>
            </div>

            <div class="button-theme icof icoF553" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="goCoaches" href="#">Тренеры клуба</a></div>
            </div>

            <div class="button-theme icof icoF1E0">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="doShare" href="#">Поделиться</a></div>
            </div>

            <div class="button-theme icof icoF2F5" v-if="[14].includes(+whoami.role_id) && +whoami.id !== +focusclub.owner_id">
              <div :class="'menu-item us-com ' + clubOptions.buttons"><a @click.prevent="$store.dispatch('toggleBottomMenu', { menu: 'clubleave', visible: true })" href="#">Выйти из клуба</a></div>
            </div>

            <BotMenu menu="clubleave" v-if="[14].includes(+whoami.role_id)">
              <div class="qw">
                <div class="qw-title">Выйти из клуба</div>
                <img class="qw-ico" :src="ATT_URL + 'pictures/' + focusclub.logo_id" alt="" />
                <div class="qw-desc">Вы действительно хотите покинуть клуб «{{ focusclub.title }}»?</div>
                <a @click.prevent="doClubLeave" href="#" class="mdc-button__label alert"> Покинуть клуб </a>
                <a @click.prevent="$store.dispatch('toggleBottomMenu', { menu: 'clubleave', visible: false })" href="#" class="mdc-button__label"> Отмена </a>
              </div>
            </BotMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MxApi } from '../assets/js/mix/MxApi';
import { MxStore } from '../assets/js/mix/MxStore';
import Rainbow from '@/components/Rainbow.vue';
import { swipe } from '@/assets/js/swiper';
import BotMenu from '@/components/modal/BotMenu.vue';

export default {
  name: 'ClubSettings',

  components: {
    Rainbow,
    BotMenu,
  },

  mixins: [MxApi, MxStore],

  mounted() {
    document.querySelector('html').style.touchAction = 'pan-down';
    const thisComponent = this;

    const t = setInterval(() => {
      const myblock = document.querySelector('#club-settings');

      if (myblock) {
        clearInterval(t);

        swipe(myblock, { maxTime: 1000, minTime: 100, maxDist: 150, minDist: 60 });
        myblock.addEventListener('swipe', function (e) {
          if (e.detail.dir === 'down') {
            thisComponent.$store.dispatch('toggleClubMenu', false);
          }
        });
      }
    }, 100);

    document.addEventListener('click', (e) => {
      if (!this.flOpenedClubMenu) {
        return;
      }
      const div = this.$refs.settingsModalClub;
      const btn = document.querySelector('#club-btn-' + this.focusClubId + '.club-settings-btn');
      const btn2 = document.querySelector('#btnid-c-c-' + this.focusClubId);

      const withinBoundaries = e.composedPath().includes(div);
      const withinBoundaries2 = e.composedPath().includes(btn);
      const withinBoundaries3 = e.composedPath().includes(btn2);

      if (!withinBoundaries && !withinBoundaries2 && !withinBoundaries3) {
        this.$store.dispatch('toggleClubMenu', false);
      }
    });
  },

  computed: {
    focusclub: function () {
      const t = this.whoami.clubs.find((t) => +t.id === +this.focusClubId);
      if (t) {
        return t;
      }
      return { title: '', logo_id: '' };
    },
  },

  methods: {
    goSettings() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/clubsettings');
    },
    goColorSettings() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/clubcolorsettings');
    },
    goClubteams() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/clubteams');
    },

    goHallOfFame() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/club/' + this.focusClubId + '/halloffame');
    },

    goLineup() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/lineup/' + this.focusClubId);
    },

    goTargets() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/clubsettings/targets');
    },

    goStaff() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/staff/' + this.focusClubId);
    },

    goFanclub() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/fanclub/' + this.focusClubId);
    },

    goBosses() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/bosses');
    },

    goCoaches() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$store.dispatch('toggleClubMenu', false);
      this.$router.push('/clubcoaches');
    },

    doShare() {
      let shareData = {
        title: 'Команда ' + this.focusclub.title,
        text: 'Команда ' + this.focusclub.title,
        url: 'https://application.cheerworld.ru/club/' + this.focusclub.title,
      };

      this.$store.dispatch('toggleBottomMenu', { menu: 'clubleave', visible: false });
      this.$store.dispatch('toggleClubMenu', false);

      this.appShare(shareData);
    },

    async doClubLeave() {
      await this.apiCall('POST', 'club/leave', { id: this.focusclub.id });

      await this.$store.dispatch('whoami');

      this.$forceUpdate();

      if (this.whoami.clubs?.length) {
        this.$store.dispatch('setFocusClubId', this.whoami.clubs.reverse()[0].id);
      } else {
        this.$store.dispatch('setFocusClubId', null);
      }

      this.$store.dispatch('toggleBottomMenu', { menu: 'clubleave', visible: false });
      this.$store.dispatch('toggleClubMenu', false);

      if (!this.whoami.clubs?.length) {
        this.$router.push('/findclub');
      }
    },

    async pinClub() {
      if (+this.whoami.options.pinClub === +this.focusClubId) {
        await this.$store.dispatch('setPinClubOption', null);
      } else {
        await this.$store.dispatch('setPinClubOption', this.focusClubId);
      }

      this.apiCall('POST', 'user/options', { data: this.whoami.options });
    },
  },
};
</script>

<style scoped lang="scss">
#club-settings {
  z-index: 21;
  user-select: none;

  .menu-item {
    // background-color: white;
    // margin: 0 40px;
    width: 100%;
    box-shadow: 2px 2px 3px;
    cursor: pointer;
    &:hover {
      box-shadow: 2px 2px 2px;
    }

    a {
      display: inline-block;
      width: 100%;
      padding: 8px 0;
    }
  }

  box-shadow: -2px -2px 2px white;

  max-height: 0;

  transition-property: 'max-height';
  transition-duration: 650ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.opened {
    max-height: 100%;
  }

  // @keyframes slidein {
  //   from {
  //     transform: translateX(0%);
  //   }

  //   to {
  //     transform: translateX(100%);
  //   }
  // }

  // animation: slidein;

  // opacity: 0.1;
  // z-index: -2;
  max-width: 500px;
  position: fixed;
  bottom: 0;
  width: 100%;
  // height: 480px;
  background-color: lightgray;
  border-radius: 25px 25px 0 0;

  .grip {
    cursor: grabbing;
    position: absolute;
    width: 100%;
    height: 25px;
    hr {
      background-color: black;
      width: 80px;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
    }
  }

  .wrapper {
    position: relative;
    height: 100%;
    margin-top: 25px;

    .options-buttons {
      display: grid;

      @media (max-height: 649px) {
        grid-template-columns: 1fr 1fr;
        font-size: 60%;
      }

      font-size: 100%;
      grid-template-columns: 1fr;

      .button-theme {
        &.icof a::before {
          margin-right: 12px;
          margin-left: 20px;
          font-family: 'Font Awesome 5 Free';
          @media (max-width: 399px) {
            display: none;
          }
        }

        &.icoF005 a::before {
          content: '\f005';
        }

        &.icoF0С0 a::before {
          content: '\f0c0';
        }

        &.icoF05b a::before {
          content: '\f05b';
        }

        &.icoF553 a::before {
          content: '\f553';
        }
        &.icoF505 a::before {
          content: '\f505';
        }
        &.icoF508 a::before {
          content: '\f508';
        }
        &.icoF1E0 a::before {
          content: '\F1E0';
        }
        &.icoF2F5 a::before {
          content: '\F2F5';
        }
        &.icoF559 a::before {
          content: '\F559';
        }
        &.icoF509 a::before {
          content: '\F509';
        }
        &.icoF53F a::before {
          content: '\F53F';
        }
        margin: 5px;
        text-align: left;
        @media (max-width: 399px) {
          text-align: center;
        }

        font-family: 'Montserrat';
        font-weight: 500;
        // display: grid;
        align-self: center;

        a {
          // display: grid;
          @media (min-height: 700px) and (min-width: 400px) {
            font-size: 130%;
          }
        }
      }
    }
  }
}

.pin-club-btn {
  position: absolute;
  right: 32px;
  top: 0px;
  opacity: 0.15;
  &.pinned {
    transform: scale(1.75);
    transition: all 0.15s ease-in;
    opacity: 1;
    text-shadow: 1px 0 1px #fff, 0 0px 2px #fff, -1px 0 2px #fff, 0 -1px 4px #333;
    color: #333;
  }

  &::before {
    content: '\f08d';
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
  }
}

.smalldir:not(:last-child)::after {
  content: ' / ';
}

.setclub-name-wrap {
  margin: 28px 0 6px 20px;
  .setclub-name {
    font-size: 140%;
  }
}

.club-mini-wrap {
  display: flex;
  margin: 0 5px 10px 0;

  .club-mini-ico {
    width: 75px;
    height: 75px;
    align-self: center;
    margin-left: 50px;
  }
  .club-mini-content {
    .title {
      font-weight: 700;
      font-size: 16px;
    }
    align-self: center;
    margin: 0 50px 0 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
}
.dirbox small {
  opacity: 0.7;
  font-size: 10px;
  font-family: 'Lato', sans-serif;
}
</style>
