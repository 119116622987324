const API_URL = 'https://api.cheerworld.ru/'
const ATT_URL = 'https://attach.cheerworld.ru/'
const WSS_URL = 'wss://ws.cheerworld.ru/'
const YMAP_APIKEY = '10bff864-b00d-4360-b996-c0a23b444b8b'
const BASE_URL = 'https://application.cheerworld.ru/'

export {
    API_URL, 
    WSS_URL,
    ATT_URL,
    YMAP_APIKEY,
    BASE_URL
}