<template>
  <div :class="{ 'welcome-mode': welcomeMode }">
    <div v-if="!hide" class="status-container">
      <div @click="hide = true" class="sw-overlay"></div>
      <div class="status-window">
        <div class="wrap">
          <template v-if="welcomeMode">
            <strong class="txt-welcome">Добро пожаловать</strong>
            <img src="@/assets/img/fa/logo_gorizontal_white 1.svg" />
          </template>
          <strong class="txt-select">Выберите статуc</strong>
          <div class="txt" v-if="welcomeMode">
            Выберете 1 статус из 4 предложенных в зависимости от того, в каком статусе в ЧИР СПОРТе находитесь Вы сейчас. Совместить несколько статусов будет возможно дальше в приложении из меню Вашего личного кабинета. Обратите внимание, что статус
            “Тренер” и “Руководитель” проходят модерацию на соответствие заявки статуса. Указывайтестатус по отношению к себе верно и ДОБРО ПОЖАЛОВАТЬ)
          </div>
          <div class="txt" v-else>
            При выборе статуса “Руководитель” и “Тренер” Ваш запрос будет проходить модерацию в течении какого-то времени. И отправляя информацию убедитесь, что она соответствует действительности, т.к. информация должна быть достоверной и
            соответствовать статусу в ЧИР спорте. Обладатели статуса “Спортсмен” и “Болельщик” модерацию не проходят, поэтому активациия и смена происходят сразу. Также Тренер может поменять статус спортсмену своей команды на тренера.
          </div>
          <div class="btn-wrap">
            <button @click="hide = true">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusWindow',
  props: {
    welcomeMode: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    hide: false,
  }),
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  margin: auto;
  width: 236px;
  margin-top: -20px;
  margin-bottom: -20px;
}

.status-container {
  display: grid;
  height: calc(100vh - 85px);
  position: absolute;
  z-index: 1;
  width: 100%;
}

.welcome-mode .status-container {
  height: 100vh;
}

.sw-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.status-window {
  /* display: grid; */
  /* grid-template-columns: 250px 250px; */
  /* grid-template-rows: 50% 50%; */
  /* height: calc(100vh - 85px); */
  /* width: 100%; */
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  width: 280px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
}

.txt-welcome {
  text-transform: uppercase;
  font-size: 24px;
  display: block;
  text-align: center;
}

.txt-select {
  text-transform: uppercase;
  font-size: 28px;
  display: block;
  text-align: center;
}

.txt {
  font-size: 12px;
  margin: 20px 12px 20px 12px;
}

.btn-wrap {
  text-align: center;
  button {
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 3px 32px;
  }
}
</style>
