<template>
  <div>
    <div @click="$store.dispatch('toggleBottomMenu', { menu, visible: false })" class="smooth" :class="{ hidden: !flOpenedMenu }"></div>

    <div :ref="'settings--' + menu" class="ex-settings" :class="{ opened: flOpenedMenu }">
      <div class="grip" ref="grip">
        <hr />
      </div>
      <div class="wrapper">
        <div>
          <div class="def-lay" v-if="deflay" @click.prevent=""></div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MxApi } from '@/assets/js/mix/MxApi';
import { MxStore } from '@/assets/js/mix/MxStore';
import { swipe } from '@/assets/js/swiper';

export default {
  name: 'BotMenu',

  props: {
    menu: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    flOpenedMenu() {
      return this.isVisibleBM(this.menu);
    },
  },

  watch: {
    flOpenedMenu(val) {
      if (!val) {
        return;
      }
      this.deflay = true;
      setTimeout(() => {
        this.deflay = false;
      }, 600);
    },
  },

  data: () => ({
    deflay: false,
  }),

  mounted() {
    document.querySelector('html').style.touchAction = 'pan-down';

    const thisComponent = this;
    const myblock = this.$refs['settings--' + this.menu];

    swipe(myblock, { maxTime: 1000, minTime: 100, maxDist: 150, minDist: 60 });

    this.$nextTick(() => {
      myblock.addEventListener('swipe', function (e) {
        if (e.detail.dir === 'down') {
          thisComponent.$store.dispatch('toggleBottomMenu', { menu: thisComponent.menu, visible: false });
        }
      });

      return;
      const div = this.$refs['settings--' + this.menu];

      document.addEventListener('click', (e) => {
        // e.preventDefault()
        if (!this.flOpenedMenu) {
          return;
        }
        const withinBoundaries = e.composedPath().includes(div);

        if (!withinBoundaries) {
          this.$store.dispatch('toggleBottomMenu', { menu: thisComponent.menu, visible: false });
        }
      });
    });
  },

  mixins: [MxApi, MxStore],

  methods: {},
};
</script>

<style scoped lang="scss">
.ex-settings {
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
  user-select: none;
  box-shadow: -2px -2px 2px white;
  max-height: 0;
  transition-property: 'max-height';
  transition-duration: 650ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.opened {
    max-height: 90vh;
  }

  max-width: 500px;
  position: fixed;
  bottom: 0;

  width: 100%;
  // height: 480px;
  background-color: lightgray;
  border-radius: 25px 25px 0 0;

  .grip {
    cursor: grabbing;
    position: absolute;
    width: 100%;
    height: 25px;
    hr {
      background-color: black;
      width: 80px;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
    }
  }

  .wrapper {
    height: 100%;
    margin-top: 25px;
  }
}

.smooth {
  z-index: 100;
}

.qw {
  font-size: 20px;
  padding: 20px;
  display: grid;

  .qw-ico {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 8px;
    margin: auto;
  }

  .qw-title {
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 800;
    margin: 15px;
    color: black;
  }

  .qw-desc {
    color: #323;
    text-align: center;
    margin: 0 20px 20px 20px;
    display: grid;
    height: 100%;
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
  }

  .mdc-button__label {
    color: black;
  }
  a {
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 3px 0 20px 0;
    padding: 4px;
    font-family: 'Montserrat';
    font-weight: 500;
    text-decoration: none;
    color: rgb(3, 3, 29);
    &.alert {
      background-color: rgb(172, 13, 13);
      color: white;
      border: 1px solid red;
    }
    &.black {
      background-color: rgb(24, 21, 21);
      color: white;
      border: 1px solid black;
    }
  }
}

.def-lay {
  z-index: 900;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: red;
}
.qw-ico-double {
  display: flex;
  .arrow-join {
    margin: auto;
    &::before {
      content: '\F04E';
      font-family: 'Font Awesome 5 Free';
      box-sizing: content-box;
    }
  }
}
</style>
