<template>
  <div class="auth-over-wrap">
    <div class="logintop">
      <img src="@/assets/img/fa/logo_gorizontal_white 1.svg" />

      <div class="wrap">I Love <strong> Cheer</strong></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { hideSplasher } from '@/assets/js/utils';

export default {
  mounted() {
    // hideSplasher()
  },
};
</script>

<style lang="scss">
.auth-over-wrap {
  background-color: #714f8351;

  position: relative;
  font-family: 'Montserrat';

  button,
  input {
    font-family: 'Montserrat' !important;
  }
  button {
    text-transform: uppercase;
  }

  p {
    background-color: unset !important;
    border-radius: unset !important;
    padding: unset !important;
  }
}

.logintop {
  img {
    position: absolute;
    top: 0;
    left: 10px;
  }
  position: absolute;
  width: 100%;
  background-color: #714f83;
  .wrap {
    font-family: 'Lato';
    text-transform: uppercase;
    color: white;
    width: 100%;
    text-align: right;
    margin-left: -25px;

    font-size: 1.25em;

    @media (max-height: 539px) {
      font-size: 1em;
    }

    @media (max-height: 519px) {
      font-size: 0.75em;
    }
  }
  top: 0;
  right: 0px;
  padding: 10px 0;
}
</style>
