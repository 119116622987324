<template>
  <div class="welcome-over-wrap">
    <StatusWindow :welcomeMode="true" v-if="!+whoami.role_id" />
    <slot></slot>
  </div>
</template>

<script>
import { MxStore } from '@/assets/js/mix/MxStore';
import StatusWindow from '@/components/StatusWindow.vue';
export default {
  name: 'IntroduceRole',
  components: {
    StatusWindow,
  },
  mixins: [MxStore],
};
</script>
