<template>
  <BotMenu menu="alreadysendjoin">
    <div class="qw">
      <div class="qw-title">Заявка на вступление уже отправлена</div>

      <div class="qw-desc">Ранее вы уже отправляли заявку на вступление. Как правило обработка заявки заниманет некоторое время. Попробуйде дождаться решения, или свяжитесь с ответственным лицом.</div>

      <a @click.prevent="$store.dispatch('toggleBottomMenu', { menu: 'alreadysendjoin', visible: false })" href="#" class="black mdc-button__label"> Понятно </a>
    </div>
  </BotMenu>
</template>

<script>
import BotMenu from '@/components/modal/BotMenu.vue';

export default {
  name: 'AlreadySendJoinModal',

  components: { BotMenu },
};
</script>

<style lang="scss" scoped></style>
