<template>
  <div class="wrap-dotdotdot" @click="expand">
    <div class="menuitems" v-if="expanded">
      <div class="dddtitle">
        {{ objTitle }}
      </div>
      <div v-for="(item, i) of items" v-bind:key="item.title" :class="'ddditem' + i" class="ddditemone" @click="clickItem(item)">
        <img v-if="item.iconUrl" class="ddditem-ico" :src="item.iconUrl" />
        <span class="ddditem-title" :class="{ disabled: item?.disabled }">{{ item.title }}</span>
        <span v-if="item.status" class="ddditem-status" :class="item.class ?? ''">{{ item.status }}</span>
      </div>
    </div>

    <div class="overlay" v-if="expanded"></div>
  </div>
</template>

<script>
export default {
  name: 'DotDotDot',
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    obj: {
      type: Object,
      default: () => {},
    },

    subj: {
      type: Object,
      default: () => null,
    },

    objTitle: {
      type: String,
      default: () => '',
    },
  },

  watch: {
    expanded(val) {
      if (!val) {
        this.$emit('collapsed');
      }
    },
  },

  data: () => ({
    expanded: false,
  }),

  methods: {
    async expand(e) {
      let el = e.target;
      if (['ddditem-status', 'ddditem-title', 'ddditem-title disabled', 'ddditem-ico'].includes(el.className)) {
        el = e.target.parentNode;
      }

      const cl = el.className;
      if (cl.match(/^ddditem/)) {
        return;
      }

      if (cl.match(/^dddtitle/)) {
        return;
      }

      if (this.expanded) {
        this.expanded = false;
        return;
      }

      this.expanded = true;
    },

    async clickItem(item) {
      if (item?.disabled) {
        return;
      }

      if (this.subj) {
        await item.action(this.subj, this.obj);
      } else {
        if (typeof item.subj !== 'undefined') {
          await item.action(item.subj, this.obj);
        } else {
          await item.action(this.obj);
        }
      }

      this.expanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-dotdotdot {
  position: absolute;
}

.wrap-dotdotdot.c-items-la .menuitems {
  min-width: 70vw !important;
}
.wrap-dotdotdot.c-items-la .menuitems > :not(:first-child) {
  text-align: left;
  padding-left: 10px;

  display: flex;
  align-items: center;
  .ddditem-title {
    margin-top: 1px;

    max-width: calc(100vw - 160px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ddditemone {
  text-align: left;
}

.wrap-dotdotdot:not(.mask) {
  right: 3px;
  top: 3px;
  font-size: 25px;
  cursor: pointer;
  &::before {
    text-shadow: 1px 1px white;
    content: '\F142';
    font-family: 'Font Awesome 5 Free';
    box-sizing: content-box;
    opacity: 0.5;
  }
}

.wrap-dotdotdot.mask {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: unset;
}

.menuitems {
  animation: showma 0.5s linear;
}

@keyframes showma {
  0% {
    transform: scale(0) translateX(100%);
  }
  50% {
    transform: scale(1);
    right: 40px;
  }
  60% {
    transform: scale(1);
    right: 18px;
  }
  100% {
    right: 15px;
    transform: scale(1);
  }
}

.overlay {
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.menuitems {
  z-index: 2;
  position: absolute;
  right: 15px;
  top: -75%;
  min-width: 200px;
  background-color: white;
  color: black;
  padding: 4px;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Montserrat';
  border: 1px solid black;
  border-radius: 5px;

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid black;
    }

    &.dddtitle {
      font-size: 18px;
      font-weight: 500;
      max-width: calc(100vw - 65px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ddditem-status {
    font-size: 8px;
    opacity: 0.6;
    margin-left: 10px;
    word-break: keep-all;
    max-width: 126px;
    white-space: initial;
    text-align: center;
    border: 1px solid gray;
    padding: 2px 2px;
    border-radius: 2px;

    &.ddd-sta-green {
      color: white;
      background-color: green;
    }
  }

  .ddditem-title.disabled {
    opacity: 0.6;
  }
}

.ddditem-ico {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
</style>
