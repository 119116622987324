import Vue from 'vue'
import Vuex from 'vuex'
import { appApiHttpRequest, SC_REDIRECTOR_LOGIN } from "@/assets/js/utils";
import router from '../router'


const sortByCreatedAt = (a,b) => {
  if ( a.created_at < b.created_at ){
    return -1;
  }
  if ( a.created_at > b.created_at ){
    return 1;
  }
  
  return a.id < b.id ? -1 : 1;

}

const valFeatureHamburerMenu = !!localStorage.getItem('FeatureHamburerMenu')??false

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    bigLoading: true,
    whoami: null, //{"id":"58901","name":"aa","surname":"aa","patron":"aa","birth":"2001-02-08","phone":"79000000000","email":"test@test.test2","city_id":"20509","photo_id":"6a34b7b6a9e3a0874f2e3e10bd3962b5af2e9233b4b92bc27c2269f74bb84ac3","avatar_id":"0a028052f363a6f35786f082a1895128c6f57839ad9b655150777558d30e5b21","avatar_meta":"{\"psize\":100,\"diffx\":0,\"diffy\":0}","role_id":"15","created_at":"2022-12-09 07:07:16","updated_at":"2022-12-09 07:10:35","phone_confirm":null,"email_confirm":"1","is_full_register":"1","region_id":"25041","sex":"1","directions":["236"]},

    seasons: [],

    defCity: null,

    route: null,

    privateReg: null,

    refRoles: [],
    refTeamRoles: [],
    refCountries: [{ id: 'rus', title: 'Россия' }],
    refRegions: [],
    refCities: [],
    refDirections: [],
    refDircodes: [],

    refAgeGroups: [
      {
        id: 'B',
        title: 'Младшие дети (4-5 лет)',
      },
      {
        id: 'M',
        title: 'Дети (6-7 лет)',
      },
      {
        id: 'Y',
        title: 'Мальчики/девочи (8-11 лет)',
      },
      {
        id: 'YJ',
        title: 'Юноши/девушки (12-14 лет)',
      },
      {
        id: 'J',
        title: 'Юниоры/юниорки (15-18 лет)',
      },
      {
        id: 'S',
        title: 'Мужчины/женщины (16 лет+)',
      },
    ],

    // flags
    flOpenedProfileMenu: false,
    flOpenedTeamMenu: false,
    flOpenedClubMenu: false,
    newNotifiesFlag: false,
    homeworksFlags: [], // only athlets(r=16) : list id teams with homework 

    myteamFlags: {
      teamId: "",
      alerts: [],
      warns: [],
      chat: false
    },

    myclubFlags: {
      clubId: "",
      chat: false
    },


    // dyn flag
    flVisibleBottom: {
      quit: {visible: false},
      leave: {visible: false},
      logoutall: {visible: false},
      jointeam: {visible: false},
      joinclub: {visible: false},
      jointeamtoclub: {visible: false},
      alreadysendjoin: {visible: false},
      inviteteamtoclub: {visible: false},
      inviteusertoclub: {visible: false},
      inviteusertoteam: {visible: false},
      badinvite: {visible: false},
      moderteamaccept: {visible: false},
      moderteamreject: {visible: false},
      moderclubaccept: {visible: false},
      moderclubreject: {visible: false},
      moderteam2caccept: {visible: false},
      moderteam2creject: {visible: false},
      teamleave: {visible: false},
      clubleave: {visible: false},
      killregalie: {visible: false},
      delteam: {visible: false},
      delclub: {visible: false},
      killsoclink: {visible: false},
      killtarget: {visible: false},
      killdzlink: {visible: false},
      killgalitem: {visible: false},
      killdzfile: {visible: false},

      extsite: {visible: false, extSiteAddr: null},
      performhw: {visible: false},
      delhw: {visible: false},
      deltc: {visible: false},
      nopasthw: {visible: false},
      nopasttraining: {visible: false},
      delseason: {visible: false},
      leaver:{visible: false, surefn: ()=>{}},
      autofillsure: {visible: false},
      exportdatasure: {visible: false},
      deltheme: {visible: false},
      copyraz: {visible: false},
      resetraz: {visible: false},
      delnotify: {visible: false},
      delallnotifies: {visible: false},

      promote2coach: {visible: false},
      firefan: {visible: false},
      fireathlet: {visible: false},
      firecoach: {visible: false},
      
      fireboss: {visible: false},
      fireteam: {visible: false},
      delegateteamowner: {visible: false},
      delegateclubowner: {visible: false},

      delthemecol: {visible: false},
      
      delseasonitem: {visible: false},
      openrole: {visible: false},
      closerole: {visible: false},
      nocloserole: {visible: false},

      getpremiumbm: {visible: false}
    },


    lastNewsItem: null,

    // current team
    focusTeamId: null,

    // current club
    focusClubId: null,


    // openTeams
    openTeamList: [],

    // openClubs
    openClubList: [],

    cacheTreeSections: [],
    cacheArticleParents: [],

    startingLoaded: false,

    ourCount: '',
    allStats: null,

    bossModeCoachTeam: null,

    globalHomeSpinner: false,

    teamchathistory:{
      teamId: null,
      messages: [],
      topLimit: false
    },

    clubchathistory:{
      clubId: null,
      messages: [],
      topLimit: false
    },

    featureHamburerMenu: valFeatureHamburerMenu,

  },

  getters: {



    focusTeamId(state){
      if(+state.whoami.role_id===14){
        return state.bossModeCoachTeam
      }

      return state.focusTeamId

    },

    teamOptions(state){

      if (!state?.whoami?.teams?.length && !state?.whoami?.clubs?.length
            
          ){
        return null
      }

      let t = null

      if(+state.whoami.role_id===14 && state.bossModeCoachTeam){
          const teamID =  state.bossModeCoachTeam 
          for(const c of state.whoami.clubs){
              t = c.teams.find((t)=>+t.id===+teamID)
              if(t){
                  break
              }
          }

      } else {
          const teamID = state.focusTeamId
          t = state?.whoami?.teams?.find((t)=>+t.id===+teamID)
      }   


      if(t){
          return t.options
      }

      return null
      return {title:'',logo_id:''}

      if(state){
        return this.focusteam.options
      }

      return null

      if (!state.whoami.teams||!state.whoami.teams.length){
        return null
      }
      const teamId = state.focusTeamId
      for(const team of state.whoami.teams){
        if(+team.id===+teamId){
          return team.options
        }
      }

      return null

    },

    clubOptions(state){
      if (!state.whoami.clubs||!state.whoami.clubs.length){
        return null
      }
      const clubId = state.focusClubId
      for(const club of state.whoami.clubs){
        if(+club.id===+clubId){
          return club.options
        }
      }

      return null

    },


    userOptions(state){
      return {
        buttons: (state.whoami.options.buttons ?? 'user-color-1') + ' us-com',
        background: (state.whoami?.options?.background ?? 'user-bg-color-1') +' us-com',
        pinTeam:  state.whoami?.options?.pinTeam ?? null,
        pinClub:  state.whoami?.options?.pinClub ?? null,
        pinRole:  state.whoami?.options?.pinRole ?? null,
        notifies: state.whoami?.options?.notifies ?? [],
      }
    },

    isUserAuth(state){
      return !!(state.whoami && state.whoami.id)
    },
    isFullRegister(state){
      return !!(state.whoami && state.whoami.id && +state.whoami.is_full_register)

    },

    roleById: (state) => (id) => {
      return state.refRoles.find(item => +item.id === +id)
    },

    directionById: (state) => (id) => {
      return state.refDirections.find(item => +item.id === +id)
    },

    dircodeById: (state) => (id) => {
      const x =  state.refDircodes.find(item => +item.id === +id)
      return x
    },

    allowRoles(state){

      if(!state.whoami||!state.refRoles.length){
        return []
      }

      let pushing = false
      let allow = []

      for(const role of state.refRoles){
          if(+role.id === +state.whoami.max_role_id){
            pushing = true
          }
          if(pushing){

            if(
              (+state.whoami.open_role_boss && +role.id === 14)
              || (+state.whoami.open_role_coach && +role.id === 15)
              || (+state.whoami.open_role_athlet && +role.id === 16)
              || (+state.whoami.open_role_fan && +role.id === 18)
            ){
              allow.push(role)
            }

            
          }

      }

      return allow
    },

    userDirectionsTitles(state){
      let dirs = []
      for(const dirId of state.whoami.directions){
        const dir = state.refDirections.find(item => +item.id === +dirId)
        if(dir){
          dirs.push(dir.title)
        }
      }
      return dirs
    },

    isVisibleBM: (state) => (menu) => {
      if(!state.flVisibleBottom[menu]){
        return false
      }
      const x = (state.flVisibleBottom[menu]&&state.flVisibleBottom[menu].visible)
      return x
    },
    isVisibleAnyBM: (state) => {
      for(const m of Object.keys(state.flVisibleBottom)){
        if(m.visible){
          return true
        }
      }
      return false
    },

    teamChatSorted: state => state.teamchathistory.messages.sort(sortByCreatedAt),
    clubChatSorted: state => state.clubchathistory.messages.sort(sortByCreatedAt),


  },
  mutations: {

    loadSeasons(state, seasons){

      for(const s of seasons){
        const oldIndex = state.seasons.findIndex(_s => +_s.id === +s.id)
        if(oldIndex >= 0){
          state.seasons.splice(oldIndex, 1)
        }
        state.seasons.push(s)
      }
    },

    deleteSeason(state, seasonId){
      const oldIndex = state.seasons.findIndex(_s => +_s.id === +seasonId)
      if(oldIndex >= 0){
        state.seasons.splice(oldIndex, 1)
      }
    },

    newteamchathistory(state, {team_id, message}){
      if(!team_id){
        return
      }
      if(+team_id !== +state.teamchathistory.teamId){
        return
      }
      if(state.teamchathistory.messages.find( m => m.id === message.id)){
        return
      }
      state.teamchathistory.messages.push(message)
    },


    loadteamchathistory(state, {team_id, messages, last}){

      if(!last){
          state.teamchathistory.topLimit = false
          state.teamchathistory.teamId = team_id
          state.teamchathistory.messages = messages
          return
      }

      state.teamchathistory.teamId = team_id

      for(const m of messages){
        if(!state.teamchathistory.messages.find((item => item.id === m.id))){
          state.teamchathistory.messages.push(m)
        }
      }

      if(!messages.length){
        state.teamchathistory.topLimit = true
      }

    },


    newclubchathistory(state, {club_id, message}){
      if(!club_id){
        return
      }
      if(+club_id !== +state.clubchathistory.clubId){
        return
      }
      if(state.clubchathistory.messages.find( m => m.id === message.id)){
        return
      }
      state.clubchathistory.messages.push(message)
    },


    loadclubchathistory(state, {club_id, messages, last}){

      if(!last){
          state.clubchathistory.topLimit = false
          state.clubchathistory.clubId = club_id
          state.clubchathistory.messages = messages
          return
      }

      state.clubchathistory.clubId = club_id

      for(const m of messages){
        if(!state.clubchathistory.messages.find((item => item.id === m.id))){
          state.clubchathistory.messages.push(m)
        }
      }

      if(!messages.length){
        state.clubchathistory.topLimit = true
      }

    },


    globalHomeSpinner(state, displayed){
      state.globalHomeSpinner = displayed
    },

    bossModeCoachTeam(state, team_id){
      state.bossModeCoachTeam = team_id
    },

    updateWhoamiTeam(state, team){
      // const t = state.whoami.teams?state.whoami.teams.findIndex((te) => +team.id === +te.id):null

      // if(t&&t>0){
      //   state.whoami.teams.splice(t, 1);
      // }
      // if(!state.whoami.teams){
        state.whoami.teams = [team]
      // } else {
      //   state.whoami.teams.push(team)

      // }
      

    },

    increaseClubLike(state, club_id){
      let c = null
      if(state.whoami.clubs){
        c = state.whoami.clubs.find((club) => +club.id === +club_id)
      }
      
      if(c){
        c.likes= +c.likes + (+changed)
        c.is_liked= +changed===1 ? "1" : "0"
      }
    },

    increaseTeamLike(state, {team_id, changed}){
      if(+state.whoami.role_id===14){
        for(const club of state.whoami.clubs){

          const t = club.teams.find((team) => +team.id === +team_id)
          if(t){
            t.likes= +t.likes + (+changed)
            t.is_liked= +changed===1 ? "1" : "0"
          }
    

        }
        return;

      }
      let t = null
      if(state.whoami.teams){
        t = state.whoami.teams.find((team) => +team.id === +team_id)
      }
      
      if(t){
        t.likes= +t.likes + (+changed)
        t.is_liked= +changed===1 ? "1" : "0"

      }

      if(state.whoami.clubs){

        for(const club of state.whoami.clubs){

          let t = null
          if(club.teams){
            t = club.teams.find((team) => +team.id === +team_id)
          }
          
          if(t){
            t.likes= +t.likes + (+changed)
            t.is_liked= +changed===1 ? "1" : "0"
          }
      
        }
  
      }


    },

    resetraz(state){
      state.whoami.razrad = ''
    },

    setMyteamFlags(state, val){
      state.myteamFlags = val
    },
    setMyclubFlags(state, val){
      state.myclubFlags = val
    },

    setHomeworksFlags(state, val){
      state.homeworksFlags = val
    },

    setOurCount(state, val){
      state.ourCount = val+" "
    },

    setAllStats(state, val){
      state.allStats = val
    },

    toggleOffLeaver(state){
      state.flVisibleBottom.leaver.surefn = null
      state.flVisibleBottom.leaver.visible = false

    },
    toggleBottomMenu(state, {menu, visible, extSiteAddr, surefn}){
        state.flVisibleBottom[menu].visible = visible
        if(extSiteAddr){
          state.flVisibleBottom[menu].extSiteAddr = extSiteAddr

        }

        if(surefn){
          state.flVisibleBottom[menu].surefn = surefn

        }


    },

    setStartingLoaded(state){
      state.startingLoaded = true
    },

    setCacheArticleParents(state, items){
      for(const item of items){
        state.cacheArticleParents.unshift(item)
      }
    },

    setCacheTreeSections(state, val){
      state.cacheTreeSections = val
    },

    lastNewsItem(state, val){
      state.lastNewsItem = val
    },

    openTeamList(state, val){
      state.openTeamList = val
    },

    openClubList(state, val){
      state.openClubList = val
    },

    setNewNotifiesFlag(state, val){
      state.newNotifiesFlag = val
    },

    setFocusTeamId(state, val){
      state.focusTeamId = val
    },

    setFocusClubId(state, val){
      state.focusClubId = val
    },


    setBackgroundOption(state, val){
      state.whoami.options.background = val
    },
    setButtonsOption(state, val){
      state.whoami.options.buttons = val
    },
    setPinOption(state, val){
      state.whoami.options.pinTeam = val
    },

    setPinClubOption(state, val){
      state.whoami.options.pinClub = val
    },


    setPinRoleOption(state, val){
      state.whoami.options.pinRole = val
    },


    setBackgroundTeamOption(state, {teamId, val}){
      if (!state.whoami.teams||!state.whoami.teams.length){
        return
      }

      let tInd = -1
      for(const team in state.whoami.teams){
        if(+state.whoami.teams[team].id===+teamId){
          tInd = team
          break
        }
      }

      if(tInd>=0){
        state.whoami.teams[tInd].options.background = val

      }
    },
    setButtonsTeamOption(state, {teamId, val}){
      if (!state.whoami.teams||!state.whoami.teams.length){
        return
      }
      let tInd = -1
      for(const team in state.whoami.teams){
        if(+state.whoami.teams[team].id===+teamId){
          tInd = team
          break
        }
      }

      if(tInd>=0){
        state.whoami.teams[tInd].options.buttons = val

      }
    },



    setBackgroundClubOption(state, {clubId, val}){
      if (!state.whoami.clubs||!state.whoami.clubs.length){
        return
      }

      let tInd = -1
      for(const club in state.whoami.clubs){
        if(+state.whoami.clubs[club].id===+clubId){
          tInd = club
          break
        }
      }

      if(tInd>=0){
        state.whoami.clubs[tInd].options.background = val

      }
    },
    setButtonsClubOption(state, {clubId, val}){
      if (!state.whoami.clubs||!state.whoami.clubs.length){
        return
      }
      let tInd = -1
      for(const club in state.whoami.clubs){
        if(+state.whoami.clubs[club].id===+clubId){
          tInd = club
          break
        }
      }

      if(tInd>=0){
        state.whoami.clubs[tInd].options.buttons = val

      }
    },



    toggleProfileMenu(state, val){
      state.flOpenedProfileMenu = val
    },
    toggleTeamMenu(state, val){
      state.flOpenedTeamMenu = val
    },

    toggleClubMenu(state, val){
      state.flOpenedClubMenu = val
    },


    setRoute(state, val){
      state.route = val
    },

    async setWhoami(state, {val, defCity}){
      state.whoami = val
      state.defCity = defCity
      await this.dispatch('appendGeoDataToWhoamiTeams')
    },

    setPrivateReg(state, val){
      state.privateReg = val
    },

    setRoles(state, val){
      state.refRoles = val
    },

    setTeamRoles(state, val){
      state.refTeamRoles = val
    },

    setRegions(state, val){
      state.refRegions = val
    },

    setCities(state, val){
      state.refCities = val
    },

    setDirections(state, val){
      state.refDirections = val
    },

    setDircodes(state, val){
      state.refDircodes = val
    },


    setRoleId(state, val){
      state.whoami.role_id = `${val}`
    },

    setLocation(state, {region_id, city_id}){
      state.whoami.city_id = `${city_id}`
      state.whoami.region_id = `${region_id}`
    },

    setPersonal(state,{sex,name,surname,patron,birth}){
      state.whoami.sex = sex,
      state.whoami.name = name
      state.whoami.surname = surname
      state.whoami.patron = patron
      state.whoami.birth = birth
    },

    setContact(state, {phone, email}){
      if(phone!==undefined){
        state.whoami.phone = phone
      }
      if(email!==undefined){
        state.whoami.email = email
      }

    },

    setPhoto(state, avatar_id){
      state.whoami.avatar_id = avatar_id
    },

    setUserDirections(state, {directions}){
      state.whoami.directions = directions

    },

    setWhoamiTeamCityTitle(state, {teamId, title}){
      const ind = state.whoami.teams.findIndex((team)=>+team.id===+teamId)
      if(ind>=0){
        state.whoami.teams[ind].city_title = title
      }
    },
    setWhoamiTeamRegionTitle(state, {teamId, title}){
      const ind = state.whoami.teams.findIndex((team)=>+team.id===+teamId)
      if(ind>=0){
        state.whoami.teams[ind].region_title = title
      }
    },

  },
  actions: {

    async loadSeason({commit}, {seasonId}){
        const season = await appApiHttpRequest('GET', 'calendar/season', {season_id: seasonId})

        if(season.status===200&&season.packet.subcode===200){
          commit('loadSeasons',  [season.packet.data.season])

        }


    },

    deleteSeason({commit}, seasonId){
      commit('deleteSeason',  seasonId)

    },

    async loadSeasonsByTeamId({commit}, {teamId}){
      
      const season = await appApiHttpRequest('GET', 'calendar/seasons', {team_id: teamId})

      if(season.status===200&&season.packet.subcode===200){
        commit('loadSeasons',  season.packet.data.seasons)

      }

    },

    async newteamchathistory({commit}, {team_id, message}){
      commit('newteamchathistory', {team_id, message})

    },

    async loadteamchathistory({state, commit}, {team_id, last}){


      const params = {
          team_id,
      }

      if(last&&state.teamchathistory.messages.length){
        const lastLoaded = state.teamchathistory.messages.sort(sortByCreatedAt)[0]
        params.last = lastLoaded.created_at
        params.last_id = lastLoaded.id
      }

      const getmsg = await appApiHttpRequest('GET', 'team/chat', params)

      if(getmsg.status===200&&getmsg.packet.subcode===200){
        commit('loadteamchathistory', {team_id,  messages: getmsg.packet.messages, last})
        

      }



    },

    async newclubchathistory({commit}, {club_id, message}){
      commit('newclubchathistory', {club_id, message})

    },

    async loadclubchathistory({state, commit}, {club_id, last}){


      const params = {
          club_id,
      }

      if(last&&state.clubchathistory.messages.length){
        const lastLoaded = state.clubchathistory.messages.sort(sortByCreatedAt)[0]
        params.last = lastLoaded.created_at
        params.last_id = lastLoaded.id
      }

      const getmsg = await appApiHttpRequest('GET', 'club/chat', params)

      if(getmsg.status===200&&getmsg.packet.subcode===200){
        commit('loadclubchathistory', {club_id,  messages: getmsg.packet.messages, last})
        

      }



    },


    globalHomeSpinner({commit}, displayed){
      commit('globalHomeSpinner', displayed)
    },

    bossModeCoachTeam({commit}, team_id){
      commit('bossModeCoachTeam', team_id)

    },

    async updateWhoamiTeam({commit}, team_id){

      const response = await appApiHttpRequest('GET', 'team/get', {id: team_id})
      if(response.status===200&&response.packet.subcode===200){
          commit('updateWhoamiTeam', response.packet.data.team)
          commit('setFocusTeamId', team_id)
          commit('bossModeCoachTeam', team_id)

          
          // await this.$store.dispatch('updateWhoamiTeam', response.packet.data.team)
          // await this.$store.dispatch('setFocusTeamId', response.packet.data.team.id)

          // await this.$store.dispatch('bossModeCoachTeam', response.packet.data.team.id)

      }

    },

    increaseClubLike({commit}, {club_id, changed}){
      commit('increaseClubLike', {club_id, changed})
    },

    increaseTeamLike({commit}, {team_id, changed}){
      commit('increaseTeamLike', {team_id, changed})
    },


    resetraz({commit}){
      commit('resetraz')

    },

    leavePage({state, commit}){
      state.flVisibleBottom.leaver.surefn()
      commit('toggleOffLeaver')
    },

    async setMyteamFlags({commit}, teamId){

      const request = await appApiHttpRequest('GET', 'team/flags',{team_id: teamId})

      if(request.status===200&&request.packet.subcode===200){

        commit('setMyteamFlags', request.packet.data)

      }

    },


    async setMyclubFlags({commit}, clubId){

      const request = await appApiHttpRequest('GET', 'club/flags',{club_id: clubId})

      if(request.status===200&&request.packet.subcode===200){

        commit('setMyclubFlags', request.packet.data)

      }

    },

    async setHomeworksFlags({commit}){

      const request = await appApiHttpRequest('GET', 'homework/flags',{})

      if(request.status===200&&request.packet.subcode===200){

        commit('setHomeworksFlags', request.packet.teams)

      }

    },

    async updateOurCount({commit}){

      const request = await appApiHttpRequest('GET', 'stat/total',{})

      if(request.status===200&&request.packet.subcode===200){

          const cnt =
            +request.packet.data.athlets +
            +request.packet.data.coaches +
            +request.packet.data.fans +
            +request.packet.data.leaders

          commit('setOurCount', cnt)
          commit('setAllStats', request.packet.data)

      }

    },

    toggleBottomMenu({commit},val){
      commit('toggleBottomMenu', val)
    },

    setStartingLoaded({commit}){
      commit('setStartingLoaded')
    },

    setCacheArticleParents({commit}, items){
      commit('setCacheArticleParents', items)
    },

    async setCacheTreeSections({commit, state}){
      if(state.cacheTreeSections.length){
        return
      }

      const request = await appApiHttpRequest('GET', 'cat/sections',{})

      if(request.status===200&&request.packet.subcode===200){

          commit('setCacheTreeSections', request.packet.items)

      }

    },

    async setLastNewsItem({commit}){
      const newsData = await appApiHttpRequest('GET', 'cat/showArticles',{from: 0, count: 1})
        if(newsData.status===200&&newsData.packet.subcode===200){
          commit('lastNewsItem', newsData.packet.items[0])
        }
    },

    async loadOpenTeams({commit}){
      const teamsData = await appApiHttpRequest('GET', 'team/find',{country_id: 1,from: 0, count: 100})
        if(teamsData.status===200&&teamsData.packet.subcode===200){
          commit('openTeamList', teamsData.packet.data.items)
        }
    },

    async loadOpenTeamsOne({commit}, team_id){
      const teamsData = await appApiHttpRequest('GET', 'team/find',{team_id})
        if(teamsData.status===200&&teamsData.packet.subcode===200){
          commit('openTeamList', teamsData.packet.data.items)
        }
    },

    async loadOpenClubs({commit}){
      const clubsData = await appApiHttpRequest('GET', 'club/find',{country_id: 1,from: 0, count: 100})
        if(clubsData.status===200&&clubsData.packet.subcode===200){
          commit('openClubList', clubsData.packet.data.items)
        }
    },

    async loadOpenClubsOne({commit}, club_id){
      const clubsData = await appApiHttpRequest('GET', 'club/find',{club_id})
        if(clubsData.status===200&&clubsData.packet.subcode===200){
          commit('openClubList', clubsData.packet.data.items)
        }
    },

    async checkNewNotifies({commit}){
      const notifyData = await appApiHttpRequest('GET', 'notify/get',{from: 0, count: 1, 'readed[]': [0]})
        if(notifyData.status===200&&notifyData.packet.subcode===200){
          commit('setNewNotifiesFlag', !!+notifyData.packet.data.total)
        }
      },

    async whoami({commit, state}){

      const whoamiData = await appApiHttpRequest('GET', 'user/whoami')



      if(whoamiData){
        if(whoamiData.packet && whoamiData.packet.subcode && SC_REDIRECTOR_LOGIN.includes(whoamiData.packet.subcode)){
          this.commit('setWhoami', {val: null, defCity: null})


          if(this.state.route!=='login'){

            router.push('/')
          }
          return
        }
        if(whoamiData.status===200&&whoamiData.packet.subcode===200){

          if(whoamiData.packet?.data?.role_id==17){
            localStorage.removeItem('xcheertoken')
            window.location = '/';
            return
          }


          const pushHome = !this.state.whoami
          const pushWelcome = !this.state.whoami && !(+whoamiData.packet.data.is_full_register)

          let defCity = null

          if(whoamiData.packet.data.city_id){

            if(state.refCities.length){
              defCity = state.refCities.find(c=>+c.id === +whoamiData.packet.data.city_id)
            } else {

              const dc = await appApiHttpRequest('GET', 'refbooks/cities', {city_id: whoamiData.packet.data.city_id})
              if(dc.packet && dc.packet.subcode ===200 ){
                if(dc.packet.data&&dc.packet.data.length===1){
                  defCity = dc.packet.data[0]
                }
              }
  

            }
            
          }


          commit('setWhoami', {val: whoamiData.packet.data, defCity})

          if(pushWelcome){

            if(this.state.route!=='welcome'){
              router.push('/welcome')
            }
            return

          }

          if(pushHome){
            if(this.state.route!=='home'){
              const c = new Date()
              router.push('/home?'+c.getTime())
            }
            return

          }

        }

        if(+whoamiData.packet.data.role_id===14 && state.bossModeCoachTeam){
          const response = await appApiHttpRequest('GET', 'team/get', {id: state.bossModeCoachTeam})
          if(response.status===200&&response.packet.subcode===200){
              commit('updateWhoamiTeam', response.packet.data.team)
          }    

        }
        
      }

    },

    async appendGeoDataToWhoamiTeams({commit, state}){
      if(state.whoami&&state.whoami.teams&&state.whoami.teams.length){
        for(const team of state.whoami.teams){
          if(!team.hasOwnProperty('city_title')){

            if(state.refCities.length && state.refRegions.length){
              const city = state.refCities.find(c => +c.id===+team.city_id)
              if(city){
                commit('setWhoamiTeamCityTitle', {teamId: team.id, title: city.title})
                
                const region = state.refRegions.find(r => +r.id===+city.region_id)
                if(region){
                  commit('setWhoamiTeamRegionTitle', {teamId: team.id, title: region.title})

                }

              }
              continue
            }

            const city = await appApiHttpRequest('GET', 'refbooks/cities', {city_id: team.city_id})
            if(city.packet && city.packet.subcode ===200 ){
              if(city.packet.data&&city.packet.data.length===1){
                commit('setWhoamiTeamCityTitle', {teamId: team.id, title: city.packet.data[0].title})

                const region = await appApiHttpRequest('GET', 'refbooks/regions', {region_id: city.packet.data[0].region_id})
                if(region.packet && region.packet.subcode ===200 ){
                  if(region.packet.data&&region.packet.data.length===1){
                    commit('setWhoamiTeamRegionTitle', {teamId: team.id, title: region.packet.data[0].title})
                  }
                }

              }

            }
          }
        }
      }
    },

    setFocusTeamId({commit}, val){
      commit('setFocusTeamId', val)
    },

    setFocusClubId({commit}, val){
      commit('setFocusClubId', val)
    },


    async setRegSuccess({commit}, val){
      commit('setPrivateReg', val)
    },
    async resetRegSuccess({commit}){
      commit('setPrivateReg', null)
    },


    async setTeamRoles(){

      if(this.state.refTeamRoles.length){
        return
      }

      const refTeamRoles = await appApiHttpRequest('GET', 'refbooks/teamRoles')


        if(refTeamRoles.status===200&&refTeamRoles.packet.subcode===200){

          this.commit('setTeamRoles', refTeamRoles.packet.data)


        }
    },

    async setRoles(){

      if(this.state.refRoles.length){
        return
      }

      const refRoles = await appApiHttpRequest('GET', 'refbooks/roles')


        if(refRoles.status===200&&refRoles.packet.subcode===200){

          this.commit('setRoles', refRoles.packet.data)


        }

    },

    async setRegions(){

      if(this.state.refRegions.length){
        return
      }

      const refRegions = await appApiHttpRequest('GET', 'refbooks/regions')


      if(refRegions.status===200&&refRegions.packet.subcode===200){

        this.commit('setRegions', refRegions.packet.data)


      }

    },

    async setCities(){

      if(this.state.refCities.length){
        return
      }

      const refCities = await appApiHttpRequest('GET', 'refbooks/cities')


      if(refCities.status===200&&refCities.packet.subcode===200){

        this.commit('setCities', refCities.packet.data)


      }

    },

    async setDirections(){

      if(this.state.refDirections.length){
        return
      }

      const refDirections = await appApiHttpRequest('GET', 'refbooks/directions')


      if(refDirections.status===200&&refDirections.packet.subcode===200){

        this.commit('setDirections', refDirections.packet.data)


      }

    },

    async setDircodes(){

      if(this.state.refDircodes.length){
        return
      }

      const refDircodes = await appApiHttpRequest('GET', 'refbooks/dircodes')


      if(refDircodes.status===200&&refDircodes.packet.subcode===200){

        this.commit('setDircodes', refDircodes.packet.data)


      }

    },

    setRoute({commit}, val){
      commit('setRoute', val)
    },

    toggleProfileMenu({commit}, val){
      commit('toggleProfileMenu', val)
    },

    toggleTeamMenu({commit}, val){
      commit('toggleTeamMenu', val)
    },

    toggleClubMenu({commit}, val){
      commit('toggleClubMenu', val)
    },

    updateWhoamiRole({commit},{role_id}){
      if(this.state.whoami){
        commit('setRoleId', role_id)
      }
    },

    updateWhoamiLocation({commit},{region_id, city_id}){
      if(this.state.whoami){
        commit('setLocation', {region_id, city_id})
      }
    },

    updateWhoamiPersonal({commit},val){
      if(this.state.whoami){
        commit('setPersonal', val)
      }
    },

    updateWhoamiContact({commit},val){
      if(this.state.whoami){
        commit('setContact', val)
      }
    },

    updateWhoamiPhoto({commit},val){
      if(this.state.whoami){
        commit('setPhoto', val)
      }
    },

    updateWhoamiDirections({commit},val){
      if(this.state.whoami){
        commit('setUserDirections', val)
      }
    },

    setBackgroundOption({commit}, val){
      commit('setBackgroundOption', val)
    },

    setButtonsOption({commit}, val){
      commit('setButtonsOption', val)
    },

    setPinOption({commit}, val){
      commit('setPinOption', val)
    },
    setPinClubOption({commit}, val){
      commit('setPinClubOption', val)
    },
    setPinRoleOption({commit}, val){
      commit('setPinRoleOption', val)
    },

    setBackgroundTeamOption({state,commit}, val){
      const teamId = state.focusTeamId
      if(teamId!==null){
        commit('setBackgroundTeamOption', {val, teamId})
      }
    },

    setButtonsTeamOption({state, commit}, val){
      const teamId = state.focusTeamId
      if(teamId!==null){
        commit('setButtonsTeamOption', {val, teamId})

      }
    },

    setBackgroundClubOption({state,commit}, val){
      const clubId = state.focusClubId
      if(clubId!==null){
        commit('setBackgroundClubOption', {val, clubId})
      }
    },

    setButtonsClubOption({state, commit}, val){
      const clubId = state.focusClubId
      if(clubId!==null){
        commit('setButtonsClubOption', {val, clubId})

      }
    },



  },



  modules: {
  }
})

/*
import { createStore } from 'vuex'
import { appApiHttpRequest } from '../assets/js/utils'
import router from '../router'




const scRedirectorLogin = [460, 461]

export default createStore({


  state: {
    bigLoading: true,
    whoami: null,
    route: null,
    refRoles: [],
    refRegions: [],
    refDirections: [],

  },



  mutations: {
    setBigLoading(state){
      state.bigLoading = true
    },
    resetBigLoading(state){
      state.bigLoading = false
    },
    setWhoami(state, val){
      state.whoami = val



    },
    setRoute(state, val){
      state.route = val
    },




  },


  actions: {
    setBigLoading(){
      this.commit('setBigLoading')
    },
    resetBigLoading(){
      this.commit('resetBigLoading')
    },












    },

    async setRoles(){

      if(this.state.refRoles.length){
        return
      }

      const refRoles = await appApiHttpRequest('GET', 'refbooks/roles')


        if(refRoles.status===200&&refRoles.packet.subcode===200){

          this.commit('setRoles', refRoles.packet.data)


        }

    },





  },



getters: {
  getBigLoadingFlag(state){
    return state.bigLoading
  },
  whoami(state){
    return state.whoami
  },
  refRoles(state){
    return state.refRoles
  },
  myRole(state){
    for(const role of state.refRoles){
      if(state.whoami && role.id==state.whoami['role_id']){
        return role
      }
    }
    return null
  },
  refRegions(state){
    return state.refRegions
  },
  refDirections(state){
    return state.refDirections
  },

},

})


*/