<template>
  <BotMenu menu="getpremiumbm">
    <div class="qw">
      <div class="qw-title">Премиум подписка</div>

      <div class="qw-desc">Для выполнения данного действия нужна премиум подписка</div>

      <div class="qw-ico qw-fa-ico"></div>

      <a target="_blank" href="https://ilovecheer.ru/podpiski/" class="black mdc-button__label"> Перейти </a>
    </div>
  </BotMenu>
</template>

<script>
import BotMenu from '@/components/modal/BotMenu.vue';

export default {
  name: 'GetPremiumModal',

  components: { BotMenu },
};
</script>

<style lang="scss" scoped>
.qw-fa-ico {
  &::before {
    color: gold;
    content: '\f521';
    font-family: 'Font Awesome 5 Free';
    box-sizing: content-box;
    font-size: 48px;
    text-shadow: 1px 1px 3px black;
  }
}
</style>
