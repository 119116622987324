import { mapState, mapGetters } from 'vuex';
import { displayDate3, hideSplasher, getPlural, displayHumanDate, cutUrl, appShare, internalLocHref } from "@/assets/js/utils";
import { ATT_URL} from '@/assets/js/config'


export const MxStore = {

    computed: {

        premiumAccess(){
            return !!+this.whoami?.premium
            return !!this.whoami && !!+this.whoami.open_role_coach
        },

        ATT_URL:()=>ATT_URL,


        dayofweeks: () => ([
            {id: "1", title: "Понедельник"},
            {id: "2", title: "Вторник"},
            {id: "3", title: "Среда"},
            {id: "4", title: "Четверг"},
            {id: "5", title: "Пятница"},
            {id: "6", title: "Суббота"},
            {id: "0", title: "Воскресенье"},
        ]),

        ...mapState([
            'whoami',
            'seasons',
            'startingLoaded',
            'refRoles',
            'refTeamRoles',
            'refCities',
            'refRegions',
            'refCountries',
            'refDirections',
            'refDircodes',
            'privateReg',
            'refAgeGroups',
            'flOpenedProfileMenu',
            'flOpenedTeamMenu',
            'flOpenedClubMenu',
            'newNotifiesFlag',
            'focusClubId',
            'openTeamList',
            'openClubList',
            'lastNewsItem',
            'cacheTreeSections',
            'cacheArticleParents',
            'defCity',
            'ourCount',
            'allStats',
            'flVisibleBottom',
            'homeworksFlags',
            'myteamFlags',
            'myclubFlags',
            'bossModeCoachTeam',
            'globalHomeSpinner',
            'teamchathistory',
            'clubchathistory',
            'featureHamburerMenu'
            

        ]),
        ...mapGetters([
            'focusTeamId',
            'isUserAuth',
            'isFullRegister',
            'roleById',
            'userDirectionsTitles',
            'allowRoles',
            'dircodeById',
            'directionById',
            'userOptions',
            'teamOptions',
            'clubOptions',
            'isVisibleBM',
            'isVisibleAnyBM',
            'teamChatSorted',
            'clubChatSorted',

            // 'userOptions'
        ]),

        focusteam: function(){

            let t = null

            if(+this.whoami.role_id===14 && this.bossModeCoachTeam){
                const teamID =  this.bossModeCoachTeam 
                for(const c of this.whoami.clubs){
                    t = c.teams?.find((t)=>+t.id===+teamID)
                    if(t){
                        break
                    }
                }

            } else {
                const teamID = this.focusTeamId
                t = this.whoami.teams?.find((t)=>+t.id===+teamID)
            }   


            if(t){
                return t
            }

            return null

            return {title:'',logo_id:''}
        },
    },

    methods: {

        displayRole(id) {

            // if(+id===200){
            //     id = 16
            // }
            const role = 
             this.refTeamRoles
                 .find((r)=>+r.id===+id)
             if(role){
                 return role.title
             }
        },

        internalLocHref(url){
            internalLocHref(url)
        },

        hideSplasher(){
            hideSplasher()
        },

        appShare(data){
            appShare(data)
        },

        shortFio(full){
            
            let a = []

            if(typeof(full)==='object'){
                a = [full.surname, full.name, full.patron]
            } else {
                a = full.trim().split(' ')
            }

            if(a.length===3){
                return `${a[0]} ${a[1][0]}.${a[2][0]}.`
            }
            return full
        },

        plural(cnt, words, onlyNum = false){
            const str = getPlural(cnt, words)
            if(onlyNum){
                return str.replace(/^\d*/,'')
            }
            return str
        },

        displayDate3(d, m = 0){
            return displayDate3(d, m)
        },

        displayHumanDate(d){
            return displayHumanDate(d)
        },


        dayofweek(day){
            return this.dayofweeks.find(d=>+d.id===+day).title
        },

        cutUrl: u => cutUrl(u),

        incrementDate(date_str, incrementor = 1) {
            var parts = date_str.split("-");
            var dt = new Date(
                parseInt(parts[0], 10),      // year
                parseInt(parts[1], 10) - 1,  // month (starts with 0)
                parseInt(parts[2], 10)       // date
            );
            dt.setTime(dt.getTime() + incrementor * 86400000);
            parts[0] = "" + dt.getFullYear();
            parts[1] = "" + (dt.getMonth() + 1);
            if (parts[1].length < 2) {
                parts[1] = "0" + parts[1];
            }
            parts[2] = "" + dt.getDate();
            if (parts[2].length < 2) {
                parts[2] = "0" + parts[2];
            }
            return parts.join("-");
        },





    }
/*
    computed:{

        isAuth: (v) => v.$store.getters.authorized === true,
        userId: (v) => v.$store.getters.data.id,
        userTitle: (v) => v.$store.getters.data.title,
        userEmail: (v) => v.$store.getters.data.email,
        userPhone: (v) => v.$store.getters.data.phone,
        userDescription: (v) => v.$store.getters.data.description,
        userBalance: (v) => v.$store.getters.data.balance,
        userBalanceLocked: (v) => v.$store.getters.data.balanceLocked,
        userBalancePended: (v) => v.$store.getters.data.balancePended,
        userAvatarUrl: (v) => v.$store.getters.data.ava
            ? ATTACHMENTS_URL+'/ava/'+v.$store.getters.data.ava+'.png?'+v.$store.getters.cachePostfix
            : SPA_BASE_URL+'user_default.png',
        userAvatarDefault: (v) => !v.$store.getters.data.ava,
        userSnTg: (v) => v.$store.getters.data.sn_tg,
        userSnIg: (v) => v.$store.getters.data.sn_ig,
        userSnVk: (v) => v.$store.getters.data.sn_vk,
        userSnDc: (v) => v.$store.getters.data.sn_dc

      }
*/
}

