<template>
  <div>
    <select v-model="localId" @input="onChangeVal" :disabled="!roles">
      <option v-for="role of roles" v-bind:key="role.id" :value="+role.id">
        {{ role.short }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data: () => ({
    localId: null,
  }),

  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    currentId: {
      type: Number,
      default: () => null,
    },
    doChangeVal: {
      type: Function,
      default: () => null,
    },
  },

  watch: {
    currentId(val) {
      this.localId = val;
    },
  },

  mounted() {
    this.localId = this.currentId;
  },

  methods: {
    onChangeVal(e) {
      if (this.doChangeVal) {
        this.doChangeVal(+e.target.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  // max-width: 120px;

  select {
    margin-left: 15px;
    margin-right: 15px;
    background-color: #714f83;
    color: white;
    font-weight: 900;
    border: 1px solid white;
    padding: 8px 18px 8px 8px;
    border-radius: 12px;
    width: 155px;

    outline: 0 !important;

    @media (max-width: 399px) {
      margin-left: 6px;
      width: 150px;
    }

    @media (max-width: 359px) {
      margin-left: 6px;
      margin-right: 6px;
      width: 120px;
      padding: 8px 18px 8px 2px;
    }
  }
}
</style>
