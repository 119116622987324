import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from '@/store/index'
import auth from './middleware/auth'
import guest from './middleware/guest'
import account from './middleware/account'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,

    meta:{
      middleware: guest
    }
  },

  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/SignupView.vue'),
    meta:{
      middleware: guest
    }

  },

  {
    path: '/forgot-password',
    name: 'forgot password',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ForgotPasswordView.vue'),
    meta:{
      middleware: guest
    }

  },

  {
    path: '/welcome',
    name: 'welcome',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/WelcomeView.vue'),
    
    meta:{
      middleware: account,
    }
    

  },

  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/HomeView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewsView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  
  {
    path: '/news/read/:id',
    name: 'newsread',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewsReadView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  
  {
    path: '/mission',
    name: 'mission',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MissionView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  
  {
    path: '/gym/:id',
    name: 'gymcat',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/GymCatView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/gym/view/:id',
    name: 'gympos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/GymPosView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/shop/:id',
    name: 'shopcat',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ShopCatView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/shop/view/:id',
    name: 'shoppos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ShopPosView.vue'),

    meta:{
      middleware: auth
    }
    

  },



  {
    path: '/faq/:id',
    name: 'faqcat',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FaqCatView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/faq/view/:id',
    name: 'faqpos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FaqPosView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/lineup/:id',
    name: 'lineup',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/LineupView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  

  {
    path: '/band/:id',
    name: 'band',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/BandView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  

  {
    path: '/staff/:id',
    name: 'staff',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/StaffView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  
  {
    path: '/bosses',
    name: 'bosses',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/BossesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/clubcoaches',
    name: 'clubcoaches',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubCoachesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  

  {
    path: '/clubteams',
    name: 'clubteams',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubTeamsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/clubsettings',
    name: 'clubsettings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubSettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/clubsettings/profile',
    name: 'clubsettings-profile',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubSettingsProfileView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  

  {
    path: '/clubsettings/links',
    name: 'clubsettings-links',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubSettingsLinksView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/settings/aboutme',
    name: 'my-aboutme',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyAboutMeView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamtoclub',
    name: 'teamtoclub',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamToClubView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/club/:id',
    name: 'club-id',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/fanclub/:id',
    name: 'fanclub',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FanclubView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/world',
    name: 'world',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/WorldView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  
  {
    path: '/newteam',
    name: 'newteam',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewTeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/newclub',
    name: 'newclub',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewClubView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/findteam',
    name: 'findteam',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FindTeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/newteam/setup',
    name: 'newteam-setup',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewTeamSetupView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/newclub/setup',
    name: 'newclub-setup',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewClubSetupView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/settings/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/AboutView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/settings/mypassword',
    name: 'mypassword',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyPasswordView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/settings/myregalies',
    name: 'myregalies',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyRegaliesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  

  {
    path: '/settings/gallery',
    name: 'mygallery',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyGalleryView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/teamsettings/gallery',
    name: 'teamgallery',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsGalleryView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/clubsettings/gallery',
    name: 'clubgallery',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubSettingsGalleryView.vue'),

    meta:{
      middleware: auth
    }
    

  },



  

  {
    path: '/settings/links',
    name: 'mylinks',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyLinksView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/settings/myregalies/1',
    name: 'myregalies1',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyRegalies1View.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/settings/myregalies/2',
    name: 'myregalies2',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyRegalies2View.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/settings/myregalies/3',
    name: 'myregalies3',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyRegalies3View.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/settings/myregalies/regular',
    name: 'myregaliesregular',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyRegaliesRegularView.vue'),

    meta:{
      middleware: auth
    }
    

  },



  {
    path: '/settings/myavatar',
    name: 'myavatar',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyAvatarView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/suggestnews',
    name: 'suggestnews',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/SuggestnewsView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  
  {
    path: '/notifies',
    name: 'notifies',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NotifiesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/settings',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/SettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/roles',
    name: 'roles',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/RolesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/colorsettings',
    name: 'colorsettings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ColorSettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamcolorsettings',
    name: 'teamcolorsettings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamColorSettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/clubcolorsettings',
    name: 'clubcolorsettings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubColorSettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },



  {
    path: '/settings/personal',
    name: 'settings-personal',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/SettingsPersonalView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/myteam/calendar',
    name: 'calendar-team',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/CalendarTeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/myteam/calendar/seasons',
    name: 'seasons',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/SeasonsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/myteam/calendar/seasons/new',
    name: 'newseason',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/NewSeasonView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/myteam/calendar/seasons/edit',
    name: 'editseason',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/EditSeasonView.vue'),

    meta:{
      middleware: auth
    }
    

  },




  {
    path: '/homework/',
    name: 'homework-team',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/HomeworkTeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/homework/new/:day',
    name: 'homework-team-new-day',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/HomeworkTeamNewDayView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/homework/edit/:id',
    name: 'homework-team-edit-day',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/HomeworkTeamEditDayView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/homework/view/:id',
    name: 'homework-team-show-day',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/HomeworkTeamShowDayView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/person/:id',
    name: 'person-id',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/PersonIdView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/person/:id/regalies',
    name: 'person-regalies',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/PersonRegaliesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/person/:id/regalies/:pos',
    name: 'person-regalies-pos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/PersonRegaliesPosView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/team/:id',
    name: 'team-id',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/team/:id/halloffame',
    name: 'team-id-halloffame',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamHallOfFameView.vue'),

    meta:{
      middleware: auth
    }
    

  },



  {
    path: '/team/:id/halloffame/:pos',
    name: 'team-id-halloffame-pos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamHallOfFamePosView.vue'),

    meta:{
      middleware: auth
    }
    

  },
  {
    path: '/feedback',
    name: 'feedback',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FeedbackView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings',
    name: 'teamsettings',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/archive',
    name: 'teamsettings-archive',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsArchiveView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/links',
    name: 'teamsettings-links',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsLinksView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/teamsettings/halloffame',
    name: 'teamsettings-halloffame',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsHallOfFameView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/halloffame/regular',
    name: 'teamsettings-halloffame-regular',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamHallOfFameRegularView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/halloffame/:pos/',
    name: 'teamsettings-halloffame-pos',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsHallOfFamePosView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/profile',
    name: 'teamsettings-profile',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsProfileView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamsettings/targets',
    name: 'teamsettings-targets',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamSettingsTargetsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/myteam',
    name: 'myteam',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyTeamView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/myteam/themes',
    name: 'mythemes',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyThemesView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/myteam/edittheme/:id',
    name: 'edittheme',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/EditThemeView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  


  {
    path: '/myteam/theme',
    name: 'mytheme',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/MyThemeView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/journal',
    name: 'journal',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/JournalView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/diary',
    name: 'diary',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/DiaryView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/teamchat',
    name: 'teamchat',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/TeamChatView.vue'),

    meta:{
      middleware: auth
    }
    

  },


  {
    path: '/clubchat',
    name: 'clubchat',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/ClubChatView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/journal/statistic',
    name: 'journalstatistic',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/JournalStatisticView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/journal/statistic/:id',
    name: 'detailstatistic',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/DetailStatisticView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/journal/payments/',
    name: 'journalpayments',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/JournalPaymentsView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/favorites',
    name: 'favorites',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/FavoritesView.vue'),

    meta:{
      middleware: auth
    }
    

  },

  {
    path: '/invite/:id',
    name: 'invite-id',
    // route level code-splitting
    // this generates a separate chunk ([hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "" */ '../views/InviteIdView.vue'),

    meta:{
      middleware: auth
    }
    

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


router.beforeEach((to, from, next) => {

  const IsItABackButton = window.popStateDetected

  window.popStateDetected = false
//   if (IsItABackButton ) {
// console.log('store.getters.isVisibleAnyBM',store.getters.isVisibleAnyBM)
//     if(store.getters.isVisibleAnyBM){
//       next(false)
//       return ''

//     }
//     next()
//     return

//    }



  store.dispatch('setRoute', to.name)
  if(to.meta.middleware){

      to.meta.middleware({store, next})



  } else {
  }

  
})

router.afterEach((to, from) => {

  const main = document.querySelector('main')
  if(main){
    main.scrollTop = 0
  }
  
})


export default router
