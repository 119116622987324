<template>
  <div class="wrap" v-if="club">
    <div class="us-com club-box" :class="club.options.background">
      <FavBar entity="club" :favId="+club.id" />

      <div class="club-top">
        <div class="bosses">
          <router-link :to="'/person/' + boss.id" v-for="boss of club.bosses.slice(0, 2)" v-bind:key="boss.id">
            <figure class="boss1">
              <img :src="ATT_URL + 'pictures/' + boss.avatar_id + '?s=40x40'" />
              <figcaption>
                <div class="coachword">Руководитель</div>
                {{ shortFio(boss) }}
              </figcaption>
            </figure>
          </router-link>
        </div>
        <div class="logo">
          <div class="img" :style="'background-image: url(' + ATT_URL + 'pictures/' + club.logo_id + '?s=150x150)'"></div>
        </div>

        <div class="club"></div>
      </div>
      <div class="text">
        <div>
          <strong>{{ club.title }}</strong>
        </div>
        <div class="group group-dir">
          <div v-if="club.directions && club.directions.length && refDirections.length" class="dirbox">
            <small class="smalldir" v-for="td of club.directions" v-bind:key="td">
              {{ directionById(td).title.toLowerCase() }}
            </small>
          </div>
        </div>

        <div class="group group-codes">
          <div v-if="club.agegroup" class="agebox">
            <small>{{ club.agegroup.replaceAll(',', ' / ') }}</small>
          </div>
        </div>

        <div class="group group-geo">
          <div>
            <small>Россия</small>
          </div>

          <div v-if="club.region_title">
            <small>
              {{ club.region_title }}
            </small>
          </div>

          <div v-if="club.city_title">
            <small>
              {{ club.city_title }}
            </small>
          </div>
        </div>
      </div>

      <div class="found-text">
        <div class="fyear" v-if="club.foundation">Основан в {{ club.foundation }} году</div>
        <div class="fstory">
          {{ club.story }}
        </div>
      </div>

      <div class="cteams" v-if="club.teams.length">
        <strong>Команды</strong>

        <TeamsParade
          :teams="club.teams"
          @select="
            (t) => {
              $router.push('/team/' + t.id);
            }
          "
        />
      </div>

      <div class="alone-cubes">
        <CubeItem class="alone-cube" ico="f5a2" caption="Зал славы" @push="$router.push('/club/' + club.id + '/halloffame')" />
      </div>

      <div v-if="locGallery.length" class="gallery">Галерея клуба:</div>
      <GalleryFlow :files="locGallery" :mode4pl="true" />

      <LinksBar :sourceLinks="club.links ?? []" />

      <LikeBar
        :likes="+club.likes"
        :liked="!!+club.is_liked"
        :setlike="
          async () => {
            const diff = await setClubLike(club.id);
            club.likes = +club.likes + +diff;
            if (diff != 0) {
              club.is_liked = diff === -1 ? '0' : '1';
            }
          }
        "
        :transit="() => $router.push('/club/' + club.id)"
        :transitParam="club.id"
        :onmap="
          !club.address
            ? null
            : () => {
                $router.push('/world?club=' + club.id);
              }
        "
      >
        <template v-slot:joinbtn v-if="isShowJoinBtn">
          <div class="join" @click.prevent="pushJoin">
            <DotDotDot v-if="+isShowJoinMenu" class="mask c-items-la" :items="joinT2CItems()" :obj="club" :objTitle="'Вступить в клуб ' + club.title" />
          </div>
        </template>
      </LikeBar>

      <AlreadySendJoinModal v-if="+whoami.role_id === 14" />

      <JoinClubModal v-if="+whoami.role_id === 14" :club="joinedclub" @joinedSended="isAlreadySendJoin = true" />

      <JoinTeamToClubModal v-if="+whoami.role_id === 15 && whoami.teams && whoami.teams.length" :club="joinedclub" :forceTeam="forceTeam" @joinedSended="joinedSended" @joinedResponsed="joinedResponsed" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { MxStore } from '@/assets/js/mix/MxStore.js';
import { MxParse } from '@/assets/js/mix/MxParse.js';
import { MxLikes } from '@/assets/js/mix/MxLikes.js';
import { MxBoss } from '@/assets/js/mix/MxBoss.js';
import FavBar from '@/components/FavBar.vue';
import LikeBar from '@/components/LikeBar.vue';
import LinksBar from '@/components/links/LinksBar.vue';
import JoinClubModal from '@/components/modal/JoinClubModal.vue';
import JoinTeamToClubModal from '@/components/modal/JoinTeamToClubModal.vue';
import AlreadySendJoinModal from '@/components/modal/AlreadySendJoinModal.vue';
import DotDotDot from '@/components/DotDotDot.vue';
import GalleryFlow from '@/components/gallery/GalleryFlow.vue';
import { MxHomeWork } from '@/assets/js/mix/MxHomeWork';
import TeamsParade from '@/components/TeamsParade.vue';
import CubeItem from '@/components/uikit/CubeItem.vue';

export default {
  name: 'VetrineClub',
  components: {
    FavBar,
    LikeBar,
    LinksBar,
    JoinClubModal,
    JoinTeamToClubModal,
    AlreadySendJoinModal,
    DotDotDot,
    GalleryFlow,
    TeamsParade,
    CubeItem,
  },
  mixins: [MxStore, MxParse, MxLikes, MxBoss, MxHomeWork],

  props: {
    club: {
      type: Object,
      default: () => {},
    },
    joinbtn: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    isShowJoinBtn() {
      return (14 === +this.whoami.role_id && !this.whoami.clubs.find((c) => +c.id === +this.club.id)) || (15 === +this.whoami.role_id && this.whoami.teams && this.whoami.teams.filter((t) => +t.owner_id === +this.whoami.id).length);
    },

    isShowJoinMenu() {
      return +this.whoami.role_id === 15;
    },
  },

  data: () => ({
    joinedclub: null,
    forceTeam: null,
    requests: {},
    isAlreadySendJoin: false,
    locGallery: [],
  }),

  async mounted() {
    this.locGallery = await this.prepareDzFiles(this.club.gallery);

    if (+this.whoami.role_id === 14) {
      const alreqQ = await this.apiCall('GET', 'request/list', {});

      if (alreqQ.status === 200 && alreqQ.packet.subcode === 200 && alreqQ.packet.data.items) {
        this.isAlreadySendJoin = !!alreqQ.packet.data.items.find((r) => +r.target_id === +this.club.id);
      }
    }

    if (+this.whoami.role_id === 15) {
      const p = [];
      for (const t of this.whoami.teams) {
        p.push(this.apiCall('GET', 'request/clublist', { team_id: t.id }));
      }

      const requests = await Promise.all(p);

      let tcnt = 0;
      for (const r of requests) {
        const tkey = +this.whoami.teams[tcnt].id;
        this.requests[tkey] = [];

        if (r.status === 200 && r.packet.subcode === 200 && r.packet.data.items) {
          for (const i of r.packet.data.items) {
            this.requests[tkey].push(+i.target_id);
          }
        }

        tcnt++;
      }
    }
  },

  methods: {
    joinT2CItems() {
      function calcStatus(_t, _c, _r) {
        if (_t.club && _t.club.find((c) => +c.id === +_c.id)) {
          return 'уже в клубе';
        }

        if (_r[+_t.id] && _r[+_t.id].find((cid) => cid === +_c.id)) {
          return 'запрос отправлен';
        }

        return '';
      }

      const menus = this.whoami.teams
        .filter((t) => +t.owner_id === +this.whoami.id)
        .map((t) => ({
          title: t.title,
          action: this.joinFromDDD,
          subj: t,
          iconUrl: this.ATT_URL + 'pictures/' + t.logo_id,
          disabled: !!calcStatus(t, this.club, this.requests),
          status: calcStatus(t, this.club, this.requests),
          class: calcStatus(t, this.club, this.requests) === 'уже в клубе' ? 'ddd-sta-green' : '',
        }));

      return menus;
    },

    joinFromDDD(subjTeam, objClub) {
      this.forceTeam = subjTeam;
      this.$store.dispatch('toggleBottomMenu', {
        menu: 'jointeamtoclub',
        visible: true,
      });
    },

    pushJoin() {
      this.joinedclub = this.club;
      if (+this.whoami.role_id === 14) {
        if (this.isAlreadySendJoin) {
          this.$store.dispatch('toggleBottomMenu', {
            menu: 'alreadysendjoin',
            visible: true,
          });
        } else {
          this.$store.dispatch('toggleBottomMenu', {
            menu: 'joinclub',
            visible: true,
          });
        }
      }
    },

    joinedSended(id) {},

    joinedResponsed(id) {
      this.requests[+this.forceTeam.id].push(+id);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
  min-width: calc(100% - 20px);
}

.club-box {
  box-shadow: 1px 0 1px #333, 0 0px 2px #333, -1px 0 2px #333, 0 -1px 4px #333;

  position: relative;
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
  min-width: calc(100% - 20px);
}

.club-top {
  display: flex;
  margin-top: 8px;
  margin-bottom: 13px;

  .bosses {
    flex: 1;
    display: grid;
    // margin-top: 25px;

    .boss1 {
      // background-color: gray;
      border-radius: 50%;
      margin: 0;
      text-align: center;
      figcaption {
        .coachword {
          font-weight: 500;
          font-family: Montserrat;
        }

        font-size: 60%;
        text-align: center;
      }
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
  }
  .club {
    flex: 1;
    // margin-top: 25px;
  }
  .logo {
    flex: 2;
    // max-width: 30vw;

    .img {
      margin: auto;
      width: 150px;
      border-radius: 50%;
      height: 150px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    img {
      // width: 100%;
      height: 100%;
      max-width: 150px;
      max-height: 150px;
      border-radius: 50%;
    }
  }
}

.text {
  text-align: center;
  max-width: 205px;
  margin: auto;

  .group {
    margin-top: 6px;
    line-height: 15px;
  }

  strong {
    font-family: 'Montserrat';
  }
}

.targets {
  margin: 25px 12px 12px 12px;

  * {
    padding: 10px 12px;
    text-align: center;
    background-color: lightgray;
    border-radius: 4px;
    margin: 8px 2px;
  }
}

.smalldir:not(:last-child)::after {
  content: ' / ';
}

.found-text {
  margin: 10px;
  .fyear {
    margin: auto;
    text-align: center;
    margin-bottom: 9px;
  }

  .fstory {
    text-align: justify;
    font-size: 10px;
  }
}

.cteams {
  text-align: center;
  margin-bottom: 18px;
}

.join {
  position: relative;
}
.gallery {
  text-align: center;
  text-decoration: underline;
  margin: 25px 0 12px 0;
}

.alone-cubes {
  display: flex;
  gap: 3px;
  > * {
    margin: auto;
  }
}
</style>
