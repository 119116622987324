<template>
  <div class="login views">
    <form v-if="!stepConfirmEmail" @submit.prevent="requestLogin()">
      <div class="form-item header">
        <h4>Нет аккаунта?</h4>
        <router-link to="/signup">Регистрация </router-link>
      </div>

      <div class="form-item">
        <label :class="{ 'error-ctrl': errors.login }" for="login-label">{{ errors.login ? 'Логин - ' + errors.login : 'Логин (Телефон или Email)' }}</label>
        <input maxlength="80" :class="{ 'error-ctrl': errors.login }" v-model="login" name="login" id="login-label" type="text" />
      </div>

      <div class="form-item">
        <label :class="{ 'error-ctrl': errors.password }" for="password-label">{{ errors.password ? 'Пароль - ' + errors.password : 'Пароль' }}</label>
        <input maxlength="40" :class="{ 'error-ctrl': errors.password }" v-model="password" name="password" id="password-label" :type="!isOpenPassword ? 'password' : 'text'" />
        <span tabindex="-1" @click.prevent="isOpenPassword = !isOpenPassword" class="btn-vis-toggle"></span>
      </div>

      <div class="form-item end">
        <label for="remember-me">
          <input type="checkbox" name="remember-me" id="remember-me" :checked="isRemember" @change="(e) => (isRemember = e.target.checked)" />
          Запомнить меня
        </label>
      </div>

      <div class="form-item">
        <button class="button">Войти</button>
      </div>

      <router-link class="form-item alternate" to="/forgot-password">Забыли пароль? </router-link>

      <p class="error-total" v-if="totalError">{{ totalError }}</p>
    </form>

    <form v-if="stepConfirmEmail" @submit.prevent="requestConfirm()" autocomplete="off">
      <div class="form-item">
        <label :class="{ 'error-ctrl': errors.code }" for="code-label">{{ errors.code ? 'Код подтверждения - ' + errors.code : 'Код подтверждения' }}</label>
        <input autocomplete="off" maxlength="18" :class="{ 'error-ctrl': errors.code }" name="pin" v-model="code" id="code-label" type="text" />
      </div>

      <div class="form-item">
        <button class="button">Подтвердить</button>
      </div>

      <p class="error-total" v-if="totalError">{{ totalError }}</p>
    </form>
  </div>
</template>
<script>
import { showSplasher } from '@/assets/js/utils';
import { MxApi } from '../assets/js/mix/MxApi';
import { MxStore } from '../assets/js/mix/MxStore';
import { initSocketClient } from '@/assets/js/socket';

export default {
  name: 'LoginView',
  components: {},

  mixins: [MxApi, MxStore],

  data: () => ({
    login: '',
    password: '',
    isRemember: false,
    code: '',
    isOpenPassword: false,

    totalError: '',
    errors: {
      login: '',
      password: '',
      code: '',
    },

    confirmData: {
      syschallenge_id: null,
      csrf_code: null,
    },

    stepConfirmEmail: false,
  }),

  mounted() {
    if (this.privateReg) {
      this.login = this.privateReg.email;
      this.password = this.privateReg.password;
      this.stepConfirmEmail = true;
      this.$store.dispatch('resetRegSuccess');
      this.requestLogin();
    }

    this.$nextTick(() => {
      this.isOpenPassword = false;
    });
  },

  methods: {
    async requestLogin() {
      this.totalError = '';
      this.errors = {};

      const l = await this.apiCall('POST', 'auth/login', {
        email: this.login,
        password: this.password,
        isRemember: this.isRemember,
      });

      if (l.packet.messages) {
        if (l.packet.messages.email) {
          this.errors.login = l.packet.messages.email;
        }
        if (l.packet.messages.password) {
          this.errors.password = l.packet.messages.password;
        }

        if (l.packet.messages.email || l.packet.messages.password) {
          this.totalError = 'Исправьте ошибки и попробуйте снова';
        }
      }

      if (!(l.status === 200 && l.packet.subcode === 200)) {
        if (!this.totalError) {
          this.totalError = l.packet.message;
        }

        if (l.packet.subcode === 457) {
          if (l.packet.data && l.packet.data.syschallenge_id && l.packet.data.csrf_code) {
            this.confirmData.syschallenge_id = l.packet.data.syschallenge_id;
            this.confirmData.csrf_code = l.packet.data.csrf_code;
            this.totalError = l.packet.data.message;
          }
          this.stepConfirmEmail = true;
        }

        return;
      }

      localStorage.setItem('xcheertoken', l.packet.data.token);
      showSplasher();

      await this.$store.dispatch('whoami');

      if (this.whoami?.id) {
        localStorage.setItem('xcheeruser', this.whoami.id);

        initSocketClient(this, this.whoami.id);

        if (window.AndroidShareHandler) {
          if (window?.AndroidShareHandler?.takeAppId) {
            const t = await window.AndroidShareHandler.takeAppId();
            if (t) {
              this.apiCall('POST', 'user/setAppId', { token: t });
            }
          }
        }
      }

      // if(this.$store.getters.whoami.is_full_register==1){
      //   this.$router.push('/')

      // }
    },

    async requestConfirm() {
      this.totalError = '';
      this.errors = {};

      const l = await this.apiCall('POST', 'syschallenges/confirm', {
        code: this.code,
        id: this.confirmData.syschallenge_id,
      });

      if (l.packet.messages) {
        if (l.packet.messages.code) {
          this.errors.code = l.packet.messages.code;
        }

        if (l.packet.messages.code) {
          this.totalError = 'Исправьте ошибки и попробуйте снова';
        }
      }

      if (!(l.status === 200 && l.packet.subcode === 200)) {
        if (!this.totalError) {
          this.totalError = l.packet.message;
        }

        return;
      }

      this.$nextTick(() => {
        this.requestLogin();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item > label {
  display: flex;
  white-space: nowrap;
  overflow: auto;
}

form {
  margin: auto;
  position: relative;
  display: grid;
  row-gap: 8px;
}

.login.views {
  height: 100vh;
  display: grid;
}

input {
  /* height: 42px; */
  padding: 18px;
  /* width: 100%; */
}

button.button {
  width: 100%;
  height: 42px;
  padding: 8px;
  margin-top: 30px;
}

.form-item {
  margin: auto;
  position: relative;
  display: grid;
  width: 240px;
}

.btn-vis-toggle {
  position: relative;
  right: 10px;
  opacity: 0.6;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  top: 50%;
  border: unset;
  bottom: 3px;
  position: absolute;

  &::before {
    content: '\f06e';
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
  }
}

.toreg {
  position: absolute;
  right: 40px;
  top: 28px;
}

label.error-ctrl {
  color: red;
}

input.error-ctrl {
  border-color: red;
}

.error-total {
  text-align: center;
  color: red;
  position: absolute;
  bottom: -50px;
  font-size: 80%;
  margin: auto;
  width: 100%;
}

.form-item.alternate {
  text-align: center;
  margin-top: 15px;
}

.form-item.end {
  justify-content: end;
}

.form-item.header {
  margin-bottom: 20px;
  text-align: center;

  h4 {
    font-size: 1.1rem;
    margin: 0 0 10px 0;
    font-weight: 500;
  }
}

form.hidden {
  display: none;
}
</style>
