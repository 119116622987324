<template>
  <div>
    <div class="smooth" :class="{ hidden: !flOpenedProfileMenu }"></div>

    <div ref="settingsModalProfile" id="profile-settings" :class="{ opened: flOpenedProfileMenu }">
      <div class="grip" ref="grip">
        <hr />
      </div>
      <div class="wrapper">
        <div>
          <div class="profile-mini-wrap">
            <img class="profile-mini-ico" :src="ATT_URL + 'pictures/' + whoami.avatar_id + '?s=75x75'" />

            <div class="profile-mini-content">
              <div class="fio">{{ whoami.surname }} {{ whoami.name }} {{ whoami.patron }}</div>

              <div v-if="whoami.directions && whoami.directions.length && refDirections.length" class="dirbox">
                <small class="smalldir" v-for="td of whoami.directions" v-bind:key="td">
                  {{ directionById(td).title.toLowerCase() }}
                </small>
              </div>
            </div>
          </div>

          <div class="options-buttons">
            <div class="button-theme icof icoF0AC">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a @click.prevent="goMission" href="/mission">Миссия I LOVE CHEER</a>
              </div>
            </div>

            <div class="button-theme icof icoF085">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="goSettings" href="/settings">Все настройки</a></div>
            </div>

            <div class="button-theme icof icoF53F">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="goColorSettings" href="/colorsettings">Вид</a></div>
            </div>

            <div class="button-theme icof icoF067" v-if="[15].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="doCreateTeam" href="/newteam">Мои команды</a></div>
            </div>

            <div class="button-theme icof icoF067" v-if="[14].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="doCreateClub" href="/newclub">Мои клубы</a></div>
            </div>

            <div class="button-theme icof icoF067" v-if="[16, 18].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="doFindTeam" href="/findteam">Мои команды</a></div>
            </div>

            <div class="button-theme icof icoF021">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a href="/roles" @click.prevent="doRoles">Мой статус</a>
              </div>
            </div>
            <div class="button-theme icof icoF004">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a href="/favorites" @click.prevent="doFavorites">Избранное</a>
              </div>
            </div>
            <div class="button-theme icof icoF1E0" @click.prevent="doShare">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a href="#">Поделиться приложением</a>
              </div>
            </div>
            <div class="button-theme icof icoF00A" @click.prevent="goFeedback">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a href="#">Обратная связь</a>
              </div>
            </div>

            <div class="button-theme icof icoF783" v-if="[14, 15].includes(+whoami.role_id)">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="goSuggestNews" href="/suggestnews">Предложить новость</a></div>
            </div>

            <div class="button-theme icof icoF2B5">
              <div :class="'menu-item us-com ' + userOptions.buttons"><a @click.prevent="goSuggestNews" href="/home">Чиравита</a></div>
            </div>

            <div class="button-theme icof icoF2F5">
              <div :class="'menu-item us-com ' + userOptions.buttons">
                <a @click.prevent="$store.dispatch('toggleBottomMenu', { menu: 'quit', visible: true })" href="/exit" class="mdc-button__label"> Выход </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MxApi } from '@/assets/js/mix/MxApi';
import { MxStore } from '@/assets/js/mix/MxStore';
import { swipe } from '@/assets/js/swiper';

export default {
  name: 'ProfileSettings',

  mounted() {
    document.querySelector('html').style.touchAction = 'pan-down';

    const thisComponent = this;
    const myblock = document.querySelector('#profile-settings');

    swipe(myblock, { maxTime: 1000, minTime: 100, maxDist: 150, minDist: 60 });
    myblock.addEventListener('swipe', function (e) {
      // console.log(thisComponent.isVisibleAnyBM)
      if (thisComponent.isVisibleAnyBM) {
        return;
      }
      if (e.detail.dir === 'down') {
        thisComponent.$store.dispatch('toggleProfileMenu', false);
      }
    });

    const div = this.$refs.settingsModalProfile;
    const btn = document.querySelector('.nav-btn.nav-btn-profile');

    document.addEventListener('click', (e) => {
      if (this.isVisibleAnyBM) {
        return;
      }

      if (!this.flOpenedProfileMenu) {
        return;
      }
      const withinBoundaries = e.composedPath().includes(div);
      const withinBoundaries2 = e.composedPath().includes(btn);

      if (!withinBoundaries && !withinBoundaries2) {
        this.$store.dispatch('toggleProfileMenu', false);
      }
    });
  },

  mixins: [MxApi, MxStore],

  methods: {
    doRoles() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/roles');
    },

    doCreateTeam() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/newteam');
    },

    doCreateClub() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/newclub');
    },

    doFindTeam() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/findteam');
    },

    doFavorites() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/favorites');
    },

    goMission() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/mission');
    },

    goSettings() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/settings');
    },

    goColorSettings() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/colorsettings');
    },

    goFeedback() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/feedback');
    },

    doShare() {
      let shareData = {
        title: 'I LOVE CHEER',
        text: 'Первое мобильное приложения для чир спорта и чирлидинга',
        url: 'Скачать https://cheerworld.ru/android/ru.ilovecheer.apk',
      };

      this.$store.dispatch('toggleBottomMenu', { menu: 'teamleave', visible: false });
      this.$store.dispatch('toggleTeamMenu', false);

      this.appShare(shareData);
    },

    goSuggestNews() {
      this.$store.dispatch('toggleProfileMenu', false);
      this.$router.push('/suggestnews');
    },
  },
};
</script>

<style scoped lang="scss">
#profile-settings {
  user-select: none;

  .menu-item {
    width: 100%;
    // background-color: white;
    // margin: 0 40px;
    box-shadow: 2px 2px 3px;
    cursor: pointer;
    &:hover {
      box-shadow: 2px 2px 2px;
    }

    a {
      padding: 8px 0;
      display: inline-block;
      width: 100%;
    }
  }

  box-shadow: -2px -2px 2px white;

  max-height: 0;

  transition-property: 'max-height';
  transition-duration: 650ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.opened {
    max-height: 100%;
  }

  // @keyframes slidein {
  //   from {
  //     transform: translateX(0%);
  //   }

  //   to {
  //     transform: translateX(100%);
  //   }
  // }

  // animation: slidein;

  // opacity: 0.1;
  z-index: 21;
  max-width: 500px;
  position: fixed;
  bottom: 0;
  width: 100%;
  // height: 480px;
  background-color: lightgray;
  border-radius: 25px 25px 0 0;

  .grip {
    cursor: grabbing;
    position: absolute;
    width: 100%;
    height: 25px;
    hr {
      background-color: black;
      width: 80px;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
    }
  }

  .wrapper {
    height: 100%;
    margin-top: 25px;

    .options-buttons {
      display: grid;

      @media (max-height: 649px) {
        grid-template-columns: 1fr 1fr;
        font-size: 60%;
      }

      font-size: 100%;
      grid-template-columns: 1fr;

      .button-theme {
        &.icof a::before {
          margin-right: 12px;
          margin-left: 20px;
          font-family: 'Font Awesome 5 Free';
          @media (max-width: 399px) {
            display: none;
          }
        }

        &.icoF085 a::before {
          content: '\f085';
        }
        &.icoF53F a::before {
          content: '\F53F';
        }
        &.icoF0AC a::before {
          content: '\F0AC';
        }

        &.icoF021 a::before {
          content: '\f021';
        }
        &.icoF00A a::before {
          content: '\f00A';
        }
        &.icoF093 a::before {
          content: '\f093';
        }
        &.icoF1E0 a::before {
          content: '\F1E0';
        }
        &.icoF2F5 a::before {
          content: '\f2F5';
        }
        &.icoF067 a::before {
          content: '\f067';
        }
        &.icoF004 a::before {
          content: '\f004';
        }
        &.icoF783 a::before {
          content: '\f783';
        }

        &.icoF2B5 a::before {
          content: '\F2B5';
        }

        &.icoF021 a::before {
          content: '\f021';
        }
        &.icoF508 a::before {
          content: '\f508';
        }

        margin: 5px;
        text-align: left;
        @media (max-width: 399px) {
          text-align: center;
        }

        font-family: 'Montserrat';
        font-weight: 500;
        // display: grid;
        align-self: center;

        a {
          // display: grid;
          @media (min-height: 700px) and (min-width: 400px) {
            font-size: 130%;
          }
        }
      }
    }
  }
}

.smooth {
  z-index: 20;
}

.profile-mini-wrap {
  display: flex;
  margin: 0 5px 10px 0;

  .profile-mini-ico {
    width: 75px;
    height: 75px;
    align-self: center;
    margin-left: 50px;
    border-radius: 50%;
  }
  .profile-mini-content {
    .fio {
      font-weight: 700;
      font-size: 16px;
    }
    align-self: center;
    margin: 0 50px 0 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
}

.dirbox small {
  opacity: 0.7;
  font-size: 10px;
  font-family: 'Lato', sans-serif;
}
</style>
