<template>
  <div class="wrap-fav" v-if="entity && favId && isFavorite !== null" :class="{ fav: isFavorite }" @click.prevent="changeFav"></div>
</template>

<script>
import { MxApi } from '@/assets/js/mix/MxApi.js';
import { MxStore } from '@/assets/js/mix/MxStore.js';

export default {
  name: 'FavBar',
  mixins: [MxApi, MxStore],

  props: {
    favId: {
      type: Number,
      default: () => null,
    },
    entity: {
      type: String,
      default: () => null,
    },
  },

  data: () => ({
    isFavorite: null,
  }),

  async mounted() {
    this.refresh();
  },

  watch: {
    focusClubId() {
      if (this.entity === 'club') {
        this.isFavorite = null;
        this.refresh();
      }
    },
    focusTeamId() {
      if (this.entity === 'team') {
        this.isFavorite = null;
        this.refresh();
      }
    },
  },

  methods: {
    async refresh() {
      const request = await this.apiCall('GET', 'favorite/' + this.entity, { id: this.favId });

      if (request.status === 200 && request.packet.subcode === 200) {
        this.isFavorite = request.packet.data.is_favorite;
      }
    },

    async changeFav() {
      const request = await this.apiCall('POST', 'favorite/' + this.entity, { id: this.favId, fav: !this.isFavorite });

      if (request.status === 200 && request.packet.subcode === 200) {
        this.isFavorite = !this.isFavorite;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-fav {
  transition: all 0.25s ease;
  -webkit-tap-highlight-color: transparent;

  &.fav {
    transform: scale(1.2);
  }

  &:not(.fav) {
    opacity: 0.4;
    transform: scale(0.8);
  }

  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 32px;
  max-height: 32px;
  border-radius: 16px;
  position: absolute;
  cursor: pointer;

  right: 8px;
  top: 8px;

  text-shadow: 1px 0 1px #333, 0 0px 2px #333, -1px 0 2px #333, 0 -1px 4px #333;
  color: #ff5722;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '\f004';
    font-family: 'Font Awesome 5 Free';
    box-sizing: content-box;
  }
}
</style>
