<template>
  <div class="wrap">
    <div class="swiper swiper-ts" :id="uid" v-if="teams.length">
      <div class="swiper-wrapper">
        <div class="cteam swiper-slide" v-for="team of teams" v-bind:key="team.id" :class="team?.options?.background" @click.prevent="$emit('select', team)">
          <div class="tico" :style="'background-image: url(' + ATT_URL + 'pictures/' + team.logo_id + '?s=80x80)'"></div>
          <div class="ttitle">
            {{ team.title }}
          </div>
          <div class="tdirs" v-if="team.directions && team.directions.length && refDirections.length">
            <div class="dirbox">
              <small class="smalldir" v-for="td of team.directions" v-bind:key="td">
                {{ directionById(td).title.toLowerCase() }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="parade-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { MxStore } from '@/assets/js/mix/MxStore';

export default {
  name: 'TeamsParade',

  props: {
    teams: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [MxStore],

  data: () => ({
    uid: '',
  }),

  created() {
    this.uid = 'uidTeamsParade' + this.name + Date.now() + parseInt(Math.random() * 10000);
  },

  mounted() {
    const len = this.teams.length;
    if (!len) {
      return;
    }
    const uid = this.uid;
    this.$nextTick(() => {
      const swiper = new Swiper('.swiper.swiper-ts#' + uid, {
        modules: [Pagination],
        direction: 'horizontal',
        //slidesPerGroup: 4,
        slidesPerView: 3,
        centerInsufficientSlides: true,
        pagination: {
          el: '.parade-pagination',
          type: 'fraction',
          formatFractionCurrent: function (number) {
            return number + '-' + Math.min(len, number + 2);
          },
          formatFractionTotal: function (number) {
            return len;
          },
          renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + ' / ' + '<span class="' + totalClass + '"></span>';
          },
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.swiper-ts {
  width: 312.5px;
}
.wrap {
  margin-bottom: 15px;
}

.parade-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  text-align: center;
  font-size: 12px;
  font-family: 'Montserrat';
  color: #323;
  font-weight: 300;
}

.cteam {
  margin: 0 7px 2px 7px;
  text-align: center;
  position: relative;
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 1px 0px 2px;
  .tico {
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80px;
    width: 80px;
  }
  .ttitle {
    margin: 8px 2px 4px 2px;
    font-size: 75%;
    overflow: hidden;
    white-space: nowrap;
  }
  .tdirs {
    font-size: 8px;
    min-height: 30px;
    display: grid;
    align-items: center;
  }
}
</style>
