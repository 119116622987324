<template>
  <div class="item-wrap">
    <div class="item">
      <button class="subitem" :id="buttonId" @click="beforePush">
        <div class="ico" :class="ico"></div>
        <a href="#" class="txt">{{ caption }}</a>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CubeItem',
  props: {
    buttonId: {
      type: String,
      default: () => undefined,
    },
    ico: {
      type: String,
      default: () => '',
    },
    caption: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    beforePush(e) {
      e.preventDefault();
      this.$emit('push');
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
  border: none;
  transition: 0.2s all;

  &:active {
    transform: scale(0.98);
  }
}

.item-wrap {
  position: relative;

  flex: 1;
  width: 95px;
  height: 95px;
  min-width: 95px;
  max-width: 95px;
  max-height: 95px;
  max-height: 95px;
  margin: 0 3px;

  .item {
    // padding:  31px;

    height: 100%;

    .subitem {
      width: 100%;
      border-radius: 20px;
      height: 100%;
      background-color: white;

      .ico {
        flex: 1;
        padding: 8px;
      }

      .ico.f013::before {
        content: '\f013';
      }
      .ico.f013B::before {
        color: #292626;
        content: '\f013';
      }

      .ico.f073::before {
        content: '\f073';
      }
      .ico.f0c0::before {
        content: '\f0c0';
      }
      .ico.f0e0::before {
        content: '\f0e0';
      }
      .ico.f0e0B::before {
        color: #292626;
        content: '\f0e0';
      }
      .ico.f518::before {
        content: '\f518';
      }

      .ico.f00c::before {
        content: '\f00c';
        color: green;
      }
      .ico.f06a::before {
        content: '\f06a';
        color: red;
      }
      .ico.f66f::before {
        content: '\f66f';
        color: #292626;
      }

      .ico.f500::before {
        content: '\f500';
      }
      .ico.f080::before {
        content: '\f080';
      }

      .ico.f080B::before {
        color: #292626;
        content: '\f080';
      }

      .ico.f1fe::before {
        content: '\f1fe';
      }
      .ico.f0f0::before {
        content: '\f0f0';
      }
      .ico.f481::before {
        content: '\f481';
      }
      .ico.f559::before {
        content: '\f559';
      }
      .ico.f5a2::before {
        content: '\f5a2';
      }
      .ico.f2bb::before {
        content: '\f2bb';
      }
      .ico.f553::before {
        content: '\f553';
      }

      .ico.f555::before {
        content: '\f555';
      }

      .ico.f66f::before {
        content: '\f66f';
      }

      .ico.f044::before {
        content: '\f044';
      }

      .ico.F0AE::before {
        content: '\F0AE';
      }

      .ico.F067::before {
        content: '\F067';
      }

      .ico::before {
        font-size: 180%;
        color: #714f83;
        width: 100%;
        font-family: 'Font Awesome 5 Free';
      }
      .txt {
        flex: 1;
        font-family: 'Montserrat';
        font-size: 12px;
        /* margin: auto; */
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 6px;
        display: grid;
        word-break: keep-all;
      }
    }
  }

  &.ath-mtf-bg-red button {
    background-color: red !important;
    .ico::before,
    .txt {
      color: white !important;
    }
  }
  &.ath-mtf-bg-yel button {
    background-color: yellow !important;
    .ico::before,
    .txt {
      color: black !important;
    }
  }

  &.cube-size-2 {
    width: 115px;
    height: 115px;
    min-width: 115px;
    max-width: 115px;
    max-height: 115px;
    max-height: 115px;

    .item .subitem {
      .ico:before {
        font-size: 40px;
      }
      .txt {
        font-size: 13px;
      }
    }
  }
}

.alone-cube.item-wrap .item button.subitem {
  background: none;
  // border: 1px solid #00000040;
  box-shadow: 1px 0 1px #333, 0 0 2px #333, -1px 0 2px #333, 0 -1px 4px #333;

  .ico {
    text-shadow: 1px 0 1px #333, 0 0px 2px #333, -1px 0 2px #333, 0 -1px 4px #333;
  }

  .ico::before {
    font-size: 48px;
    color: white;
  }
}

.item-wrap:not(.alone-cube) .txt {
  color: black !important;
}

.block.ath-mtf-bg-red {
  background-color: red;
  &::before {
    color: black !important;
  }
}

.block {
  position: relative;
  word-break: keep-all;
}

.block.ath-mtf-bg-yel {
  background-color: yellow;
  .ico::before {
    color: black !important;
  }
}

.block.coa-mtf-bg-red::after {
  content: ' ';
  position: absolute;
  left: 4px;
  top: 4px;
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}

.block.coa-mtf-bg-yel::after {
  content: ' ';
  position: absolute;
  left: 4px;
  top: 4px;
  width: 14px;
  height: 14px;
  background-color: yellow;
  border-radius: 50%;
}

.coa-mtf-bg-red::after {
  content: ' ';
  position: absolute;
  left: 8px;
  top: 8px;
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}

.coa-mtf-bg-yel::after {
  content: ' ';
  position: absolute;
  left: 8px;
  top: 8px;
  width: 14px;
  height: 14px;
  background-color: yellow;
  border-radius: 50%;
}
</style>
