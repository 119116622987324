<template>
  <div class="soclinks" v-if="sourceLinks && sourceLinks.length">
    <div>Ссылки:</div>

    <template v-if="sourceLinks.filter((val) => parseKnown(val)).length">
      <div class="icons-list">
        <div class="icons-list-wrap">
          <div class="icons-pos" v-for="onelink of sourceLinks.filter((val) => parseKnown(val))" v-bind:key="onelink">
            <a
              :href="onelink"
              target="_blank"
              class="icons"
              :class="{
                vk: detectVkontakte(onelink),
                instagram: detectInstagram(onelink),
                facebook: detectFacebook(onelink),
                telegram: detectTelegram(onelink),
                youtube: detectYoutube(onelink),
              }"
            >
            </a>
          </div>
        </div>
      </div>
    </template>

    <div v-for="onelink of sourceLinks.filter((val) => parseUnknown(val))" v-bind:key="onelink">
      <a @click.prevent="$store.dispatch('toggleBottomMenu', { menu: 'extsite', visible: true, extSiteAddr: onelink })" class="onelink ico-globus" target="_blank">{{ onelink }}</a>
    </div>
  </div>
</template>

<script>
import { MxStore } from '@/assets/js/mix/MxStore.js';
import { MxParse } from '@/assets/js/mix/MxParse.js';

export default {
  name: 'LinksBar',

  mixins: [MxStore, MxParse],

  props: {
    sourceLinks: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.soclinks {
  text-align: center;
  text-decoration: underline;

  margin: 25px 0 12px 0;

  .icons-list {
    max-height: 50px;
    .icons-list-wrap {
      display: inline-flex;
      .icons {
        margin: 4px 10px;
        display: block;
        height: 40px;
        width: 40px;
        background-size: cover;
        background-repeat: no-repeat;

        &.vk {
          background-image: url(@/assets/img/brand/vk.png);
        }
        &.youtube {
          background-image: url(@/assets/img/brand/free-icon-youtube-174883.png);
        }

        &.facebook {
          background-image: url(@/assets/img/brand/free-icon-facebook-174848.png);
        }

        &.instagram {
          background-image: url(@/assets/img/brand/free-icon-instagram-1077042.png);
        }

        &.telegram {
          background-image: url(@/assets/img/brand/free-icon-telegram-3536661.png);
        }
      }
    }
  }

  .onelink {
    cursor: pointer;
    font-size: 70%;
    color: blue;
    white-space: nowrap;
    display: inline-block;
    max-width: calc(100% - 40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ico-globus::before {
      content: '\f7a2';
      color: blue;
      font-family: 'Font Awesome 5 Free';
      transform: translate(-50%, -50%);
      font-size: 100%;
      margin-right: 4px;
    }
  }
}
</style>
